import {ApiClient} from 'adminjs';
import React, {useEffect} from 'react';
import {withTheme} from 'styled-components';
import {FormGroup, Label, Button, Icon,Box} from '@adminjs/design-system';
import mongoose from 'mongoose'
const api = new ApiClient();

const candidateResult = (props) => {
    const {theme, records, action} = props;
    const [OId, setOId] = React.useState('');
    const [result, setResult] = React.useState('');
    useEffect(() => {
        return () => {

            setResult('')
        }
    }, [])
    const submit = async () => {
        if (!OId) {
            setResult('Empty String please pass a value')
        } else if (!mongoose.Types.ObjectId.isValid(OId)) {
            setResult('Invalid testLibraryID. Please provide a valid testLibraryID.');
        } else {
            const response = await api.resourceAction(
                {
                    resourceId: 'TestLibrary',
                    actionName: 'testlibraryCopyToStaging', 
                    data: {testLibraryId: OId}, 
    
                }
            )
            console.log("Response:",response.data)
            setResult(`${response.data}`)
        }
    };

    return (
        <Box variant='white'>
            <FormGroup>
                <Label>TestLibrary Id To Copy Data of TestLibray And Question From Production To Staging</Label>
                <input
                    type="text"
                    value={OId}
                    onChange={(e) => setOId(e.target.value)}
                    style={{width: '100%', padding:"8px"}}
                />
            </FormGroup>
            <div style={{marginTop: '20px', marginBottom: "40px"}}>
                <Button style={{float: 'right'}} rounded={true} onClick={submit}>
                    <Icon icon="Migrate"/>
                    {'Copy Prod To Staging'}
                </Button>
                {result ? (
                    <textarea
                        disabled
                        style={{
                            marginTop: '20px',
                            width: '100%',
                            height: '100px',
                            color: '#000',
                        }}
                        value={result}
                    />
                ) : (
                    <div/>
                )}
            </div>

        </Box>
    );
};

export default withTheme(candidateResult)