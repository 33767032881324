import { ApiClient } from 'adminjs'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { withTheme } from 'styled-components'
import Select from 'react-select'
import { FormGroup, Label, Input } from '@adminjs/design-system'
const api = new ApiClient()

const TestLibraryIdFilter = (props) => {
    const location = useLocation();
    const resourceName = location.pathname.split('/')[2];
    const { onChange, property } = props
    const [options, setOptions] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [selectedTitle, setSelectedTitle] = useState(null)
    const [testLibraryId, setTestLibraryId] = React.useState('')

    useEffect(() => {
        const fetchOptions = async (inputValue) => {
            const response = await api.resourceAction(
                {
                    resourceId: 'TestLibrary',
                    actionName: 'fetchAllTestLibraryRecord',
                    data: {resource : resourceName, view: true, search: inputValue}
                }
            )
            const testLibraries = response.data.records.map(record => ({
                value: record._id,
                label: record.title
              }))
            setOptions(testLibraries)
        }
        fetchOptions(inputValue)
    }, [inputValue])

    const handleSelectChange = (selected) => {
        setSelectedTitle(selected)
        onChange(property.path, selected ? selected.value : '')
    }

    const handleInputChange = (e) => {
        const value = e.target.value
        setTestLibraryId(value)
        onChange(property.path, value)
    }

    const label = (resourceName == 'TestLibraryMeta') ? <Label>TestLibrary Title</Label> : <Label>TestLibrary Id / TestLibrary Title</Label>;

    return (
        <FormGroup>
            {label}
            <Select
                options={options}
                value={selectedTitle}
                onChange={handleSelectChange}
                onInputChange={(inputValue) => setInputValue(inputValue)}
                isClearable
                placeholder="Select Test Library Title"
            />
            
            { resourceName != 'TestLibraryMeta' &&
                    <Input
                    type="text"
                    value={testLibraryId}
                    onChange={handleInputChange}
                    placeholder="Or enter Test Library ID"
                    style={{ marginTop: '10px' }}
                />
            }
        </FormGroup>
    )
}

export default withTheme(TestLibraryIdFilter)
