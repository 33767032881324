import { ApiClient } from 'adminjs'
import { Box, Button, Icon, TextArea, Loader, Label } from '@adminjs/design-system'
import React from "react";
import { withTheme } from 'styled-components'
import Papa from 'papaparse';

const api = new ApiClient();

const ImportQuestions = (props) => {
    const { resource } = props;
    const [csv, setCsv] = React.useState(null);
    const [result, setResult] = React.useState("");
    const [loader, setLoader] = React.useState(false);
    const [status, setStatus] = React.useState(false);
    const [fileName, setFileName] = React.useState("");
    const [OId, setOId] = React.useState("");

    const importJson = async (event) => {
        event.preventDefault();
        setLoader(true);
        const file = csv;
        if (file) {
            const reader = new FileReader();
            
            reader.onload = async () => {
                const fileText = reader.result;
                
                const response = await api.resourceAction({
                    resourceId: 'Organization',
                    actionName: 'csvBulkInvite',
                    data: { input: fileText, orgId: OId }
                });
                
                setResult(response.data?.message || 'Something went wrong');
                setStatus(true);
                setLoader(false);
            };
    
            reader.onerror = () => {
                setResult('Error reading the file.');
                setStatus(true);
                setLoader(false);
            };
    
            reader.readAsText(file);
        } else {
            setResult('No file selected.');
            setStatus(true);
            setLoader(false);
        }
    };


    const exportCsv = async (event) => {
        setLoader(true);
        try {
            const response = await api.resourceAction({
                resourceId: 'Organization',
                actionName: 'csvExport',
                data: { orgId: OId },
            });
            if (response.data?.file) {
                const blob = new Blob([response.data.file], { type: 'text/csv;charset=utf-8;' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `exported_data_${OId || 'data'}.csv`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                setResult(response.data?.message || 'Failed to export CSV.');
            }
        } catch (error) {
            setResult('Error exporting the file.');
        } finally {
            setLoader(false);
            setStatus(true);
        }
    };

    
    return (
        <Box variant='white' style={{ padding: '40px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
            <form onSubmit={importJson}>
                <Label required style={{ fontSize: '16px', lineHeight: '20px', marginBlock: '12px' }}>CSV Upload</Label>
                <div style={{ marginBlock: '20px' }}>
                    <Label
                        style={{
                            width: 130,
                            height: 25,
                            fontSize: 15,
                            cursor: 'pointer',
                            background: '#4268f6',
                            textAlign: 'center',
                            padding: '5px 0',
                            color: '#fff',
                            borderRadius: '4px',
                            display: resource.id === 'Question' ? 'inline-block' : 'none',
                            marginBlock: '10px',
                            alignItems: 'center'
                        }}
                    >
                        <a href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSqdqb3hwW06Q_gzw47JNrXJrJaoSP1_3OPV8peufju78Dljr19Q4sif2Oxx52ahHN6nyACAL4FLNHe/pub?output=csv"
                            style={{ textDecoration: 'none', color: '#fff' }}
                            download="sample csv">
                            Sample CSV
                        </a>
                    </Label>
                    <Label htmlFor='files'
                        style={{
                            width: 130,
                            height: 25,
                            fontSize: 15,
                            cursor: 'pointer',
                            background: '#de3439',
                            textAlign: 'center',
                            padding: '5px 0',
                            color: '#fff',
                            borderRadius: '4px',
                            marginBlock: '10px',
                            alignItems: 'center'
                        }}>
                        Select File
                        <></>
                        <input id="files" accept=".csv" style={{ display: 'none' }} onChange={(event) => { 
                            setCsv(event.target.files[0]); setFileName(event.target.files[0].name); setStatus(false) }} type="file" />
                    </Label>
                    <span style={{ marginLeft: '10px', fontSize: '14px' }}>{fileName}</span>
                </div>
                <div style={{ marginBlock: '20px' }}>
                    <Label required style={{ fontSize: '16px', lineHeight: '20px', marginBottom: '8px' }}>Organization ID</Label>
                    <input
                        type="text"
                        value={OId}
                        onChange={(e) => setOId(e.target.value)}
                        placeholder="Enter Organization ID"
                        style={{
                            width: '100%',
                            padding: '10px',
                            border: '1px solid #ced4da',
                            borderRadius: '4px',
                            fontSize: '14px'
                        }}
                    />
                </div>
                <div>
                    <Button disabled={status} style={{ float: 'right', marginBottom: '20px' }} rounded type='submit'>
                        <Icon icon="WorkspaceImport" />
                        Import
                    </Button>
                    {loader
                        ? <Loader />
                        : result.length > 0 && <TextArea
                            disabled
                            style={{
                                marginTop: '20px',
                                width: '100%',
                                height: '100px',
                                color: '#000',
                                borderRadius: '4px',
                                border: '1px solid #ced4da'
                            }}
                            value={result}
                        />
                    }
                </div>
                <div>
                    <Button disabled={status} style={{ float: 'right', marginBottom: '20px', marginRight: '10px' }} rounded type='submit' onClick={exportCsv}
                    >
                        <Icon icon="WorkspaceExport" />
                        Export CSV
                    </Button>
                </div>
            </form>
        </Box>
    );
};

export default withTheme(ImportQuestions);
