import { ApiClient } from 'adminjs'
import React from "react";
import { withTheme } from 'styled-components'
import Select from "react-select";
import { FormGroup, Label, selectStyles } from '@adminjs/design-system'

const api = new ApiClient()

const TestLibraryEdit = (props) => {
    const { theme, onChange, property } = props
    console.log(theme);
    
    const [options, setOptions] = React.useState([])
    const [existingValue, setExistingValue] = React.useState([])
    const [filteredOptions, setFilteredOptions] = React.useState([])
    const [defaultValues, setDefaultValues] = React.useState([])
    const value = props.record.id

    React.useEffect(() => {
        const fetchData = async () => {
            const response = await api.resourceAction({
                resourceId: 'JobRole',
                actionName: 'multiSelectButton',
                data: { view: true, id: value }
            })
            setOptions(response.data)
        }

        const fetchExistingData = async () => {
            const response = await api.resourceAction({
                resourceId: 'JobRole',
                actionName: 'multiSelectButton',
                data: { existing: true, id: value }
            })
            setExistingValue(response.data)
        }

        fetchData()
        fetchExistingData()
    }, [])

    React.useEffect(() => {
        setFilteredOptions(options.map(e => ({ value: e._id, label: e.title })))
    }, [options])

    React.useEffect(() => {
        setDefaultValues(existingValue.map(e => ({ value: e._id, label: e.title })))
    }, [existingValue])

    return (
        <div style={{ marginBottom: "40px" }}>
            <FormGroup>
                <Label>Test Library</Label>
                <Select
                    options={filteredOptions}
                    isMulti
                    autoFocus
                    isSearchable
                    value={defaultValues}
                    onChange={(selected) => {
                        onChange(property.path, (selected || []).map(e => e.value))
                        setDefaultValues(selected || [])
                    }}
                />
            </FormGroup>
        </div>
    )
}

export default withTheme(TestLibraryEdit)