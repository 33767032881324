import React from 'react'
import {
    DrawerContent,
    Box,
    DrawerFooter,
    Button,
} from '@adminjs/design-system'
import {BasePropertyComponent, ActionHeader, LayoutElementRenderer, ApiClient, useNotice} from "adminjs"
const api = new ApiClient()

const VerifyDomain = (props) => {
    const { resource, action, record} = props
    const sendNotice = useNotice()
    const verify = async () => {
        const response = await api.resourceAction({
            resourceId: 'OrganizationDomain',
            actionName: 'verifyOrganizationDomain',
            data: {name: record.params.name, type:record.params.type, id: record.params._id}
        })
        sendNotice({ message: response.data.message, type: response.data.type })
    }
    return(
    <>
        <Box flex flexGrow={1} flexDirection="column">
            <DrawerContent>
                {action?.showInDrawer ? <ActionHeader {...props} /> : null}
                {action.layout
                    ? action.layout.map((layoutElement, i) => (
                          <LayoutElementRenderer
                              // eslint-disable-next-line react/no-array-index-key
                              key={i}
                              layoutElement={layoutElement}
                              {...props}
                              where="show"
                          />
                      ))
                    : resource.showProperties.map((property) => (
                          <BasePropertyComponent
                              key={property.propertyPath}
                              where="show"
                              property={property}
                              resource={resource}
                              record={props.record}
                          />
                      ))}
            </DrawerContent>
            <DrawerFooter>
                    <Button
                        onClick={verify}                        
                        variant="default"
                        size="lg"
                        type="button">

                       Verify Domain
                    </Button>
            </DrawerFooter>
        </Box>
    </>
    )
}

export { VerifyDomain as default, VerifyDomain }