import React from 'react'
import {FormGroup, Label, InputGroup, FormMessage} from '@adminjs/design-system'

const MarkDownViewer = (props) => {

    const {onChange, record, property} = props

    const label = property.label || '';
    const path = property.path || '';
    const value = record.params[path];
    const selector = props.where == 'list' ? record.params._id : path.replaceAll('.', '-')

    const getLatexExpression = (expression, isInline) => {
        let generator = new latexjs.HtmlGenerator({hyphenate: false});
        const { body } = latexjs.parse("$" + expression + "$", {generator: generator}).htmlDocument();
        return [
            {type: 'openTag', tagName: isInline ? 'span' : 'div', outerNewLine: true},
            {type: 'html', content: body.querySelector('.katex').outerHTML},
            {type: 'closeTag', tagName: isInline ? 'span' : 'div', outerNewLine: true}
        ]
    }
    const videoPlugin = (editor, options = {}) => {
        return {
            toHTMLRenderers: {
               code(node, { origin }) {
                   if(node.literal.indexOf('latex ') === 0) {
                       return getLatexExpression(
                           node.literal.replace('latex ', ''),
                           true
                       )
                   }
                   return origin()
                },
                codeBlock(node, { entering, origin }) {
                    if (node.info === 'type:video') {
                        return [
                            {type: 'openTag', tagName: 'video',
                                attributes: { 'controls': true, 'src': node.literal }},
                            {type: 'closeTag', tagName: 'video'}
                        ]
                    }
                    else if (node.info === 'type:audio') {
                        return [
                            {type: 'openTag', tagName: 'audio',
                                attributes: { 'controls': true, 'src': node.literal }},
                            {type: 'closeTag', tagName: 'audio'}
                        ]
                    }
                    else if (node.info === 'type:latex') {
                        return getLatexExpression(node.literal, false)
                    }
                    return origin()
                }
            }
        }
    }

    React.useEffect(() => {
        const editor = new toastui.Editor.factory({
            el: document.querySelector('#editor-' + selector),
            viewer: true,
            plugins: [videoPlugin],
            usageStatistics: false,
            initialValue: value
        });
    }, [])

    return (
        <FormGroup>
            <Label variant="light">{label}</Label>
            <InputGroup>
                <div style={{width: '100%'}} id={'editor-' + selector}/>
            </InputGroup>
            <FormMessage/>
        </FormGroup>
    )
}

export default MarkDownViewer