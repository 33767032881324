import React from 'react'
import { ApiClient, flat } from 'adminjs'
import { FormGroup, Label, TextArea, Button, Loader } from '@adminjs/design-system'

const api = new ApiClient();
const CustomInput = (props) => {
    const { record, property } = props;
    const [solution, setSolution] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false)
    const [result, setResult] = React.useState([])
    const [error, setError] = React.useState('');
    const label = property.label || '';
    const runTestCases = async (e) => {
        e.preventDefault()
        setError('')
        setIsLoading(true)
        const { data } = await api.resourceAction(
            {
                resourceId: 'Question',
                actionName: 'runTestCases',
                data: { record: flat.get(record), solution }
            }
        )
        setIsLoading(false)
        if(!data.notice) {
            const filteredResponse = data.result.submissions.map((data) => {
                data.stderr = data.stderr ? window.atob(data.stderr) : '';
                data.message = data.message ? window.atob(data.message) : '';
                data.stdout = data.stdout ? window.atob(data.stdout) : '';
                return data;
            })
            setResult(filteredResponse)
        } else {
            setError(data.notice.message)
        }
    }

    React.useEffect(()=>{
        if(record.params?.solution) {
            setSolution(record.params.solution)
        }
    },[])

    return (
        <>
            <FormGroup>
                <Label>{label}</Label>
                <TextArea type="testArea"
                value={solution}
                    onChange={(e) => {
                        record.params.solution = e.target.value
                        setSolution(e.target.value)
                    }}
                    style={{
                        marginTop: '20px',
                        width: '100%',
                        height: '100px',
                    }}
                />
                {error && <p style={{color: "red"}}> {error}</p>}
                <Button rounded={true} onClick={runTestCases} style={{ marginTop: '5px' }}>Check</Button>
                {isLoading
                    ? <Loader />
                    : result &&
                        result.length > 0 ?
                        result.map((value, index) => (
                            <div key={index} style={{ paddingTop: '2rem' }}>
                                <p>Test case:   {index + 1}</p>
                                <p>Std out:   {value?.stdout}</p>
                                <p>Std err:   {value?.stderr} </p>
                                <p>Message:    {value?.message}</p>
                                <p style={{ color: value.status.id != 3 ? "red" : "black" }}>
                                    Status:{value?.status?.description}
                                </p>
                            </div>
                        ))
                        : <div />
                }
            </FormGroup>
        </>
    )
}

export default CustomInput