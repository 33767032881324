export const ROLE_ADMIN = 'ADMIN';
export const ROLE_AUTHOR = 'AUTHOR';
export const ROLE_DATA = 'DATA';
export const ROLE_MARKETING = 'MARKETING';
export const SMART_TEST_LIBRARY_SUBTYPE = "64467b2c6fe15ecd7a67446a"
export const DISC_TEST_LIBRARY_SUBTYPE = "64cd10bf98d335f6c859838c"
export const CULTURAL_FIT_TEST_LIBRARY_SUBTYPE = "65395d6d527f5b1f26c65960"
export const DEFAULT_DOMAIN = "candidate.testlify.com"
export const DEFAULT_LANGUAGE = "en"
export const USER_TYPE = "EMPLOYER"
export const ASSESSMENT_REMINDER = "ASSESSMENT_REMINDER"

export const ACTIONS = {
  'show': 'A',
  'list': 'B',
  'edit': 'C',
  'new': 'D',
  'search': 'E',
  'bulkadd': 'G',
  'delete': 'H',
  'bulkdelete': 'I',
  'csvbulkadd': 'J',
  'moveall': 'K',
  'copyall': 'L'
}

export const RATING = {
  "1" : "1",
  "2" : "2",
  "3" : "3",
  "4" : "4",
  "5" : "5",
}

export const QuestionnaireClassification = {
  MULTI_SELECT: "MULTI_SELECT",
  SINGLE_SELECT: "SINGLE_SELECT",
  SHORT_ANSWER: "SHORT_ANSWER",
  WRITTEN_ANSWER: "WRITTEN_ANSWER",
  VIDEO_ANSWER: "VIDEO_ANSWER",
  CODING_ANSWER: "CODING_ANSWER",
  TYPING_TEST: "TYPING_TEST",
  FILE_UPLOAD: "FILE_UPLOAD",
  AUDIO_ANSWER: "AUDIO_ANSWER",
  DOCUMENT: "DOCUMENT",
  SLIDES: "SLIDES",
  SPREADSHEET: "SPREADSHEET",
  CHOICE_ANSWER: "CHOICE_ANSWER",
  NUMBER: "NUMBER",
  FILL_IN_BLANK: "FILL_IN_BLANK",
  DATE_ANSWER: "DATE_ANSWER",
  RANKING: "RANKING",
  DROPDOWN: "DROPDOWN",
  RATING: "RATING",
  CHAT_SIMULATION: "CHAT_SIMULATION"
}

export const TextFormat = {
  PLAIN: "PLAIN",
  MD: "MD",
  HTML: "HTML",
}

export const AssessmentStatus = {
  DRAFT: "DRAFT",
  PUBLISHED: "PUBLISHED",
  SUBSCRIBED: "SUBSCRIBED",
}

export const QUESTION_STATUS = {
  DRAFT: "DRAFT",
  PUBLISHED: "PUBLISHED",
}

export const TESTLIBRARY_STATUS = {
  UPCOMING: "UPCOMING",
  DRAFT: "DRAFT",
  PUBLISHED: "PUBLISHED",
}

export const ASSESSMENT_LIBRARY_STATUS = {
  TEST_ENROLLED: "TEST_ENROLLED",
  TEST_INPROGRESS: "TEST_INPROGRESS",
  TEST_FORCE_CLOSURE: "TEST_FORCE_CLOSURE",
  TEST_COMPLETED: "TEST_COMPLETED",
}

export const ChargeBeePlans = {
  WHITE_LABELED_USD_YEARLY: "white-labeled-usd-yearly",
  WHITE_LABELED_INR_YEARLY: "white-labeled-inr-yearly",

}

export const EmailTypes = {
  ASSESSMENT_INVITE: "ASSESSMENT_INVITE",
  ASSESSMENT_REJECTION: "ASSESSMENT_REJECTION",
  ASSESSMENT_RESULT: "ASSESSMENT_RESULT",
  ASSESSMENT_COMPLETION: "ASSESSMENT_COMPLETION",
  ASSESSMENT_REMINDER: "ASSESSMENT_REMINDER"
}

export const TemplateButtonStyle = {
  INVITE_BUTTON: "display:flex;height: 17px;width: 73px;border-radius: 4px;text-align:center;padding: 9px 27px;text-decoration:none;padding-left: 32px;font-size: 16px;letter-spacing: 0.025em;font-family: 'Cabin',Arial,Helvetica,sans-serif;",
  REMINDER_BUTTON: "display:flex;height: 18px;width: 169px;border-radius: 5px;text-align:center;;padding: 10px 30px;text-decoration:none;padding-left:32px;font-size: 16px;font-family: 'Cabin',Arial,Helvetica,sans-serif;letter-spacing: 0.025em;",
  RESULT_BUTTON: "display:flex;height: 18px;width: 74px;border-radius: 5px;text-align:center;padding: 10px 30px;text-decoration:none;padding-left:32px;font-size: 16px;font-family: 'Cabin',Arial,Helvetica,sans-serif;letter-spacing: 0.025em; "
}

export const SUPPORT_EMAIL = "support@testlify.com"
export const LOGO_URL = "http://hs.testlify.com/hubfs/TestlifyLogoDarkNew.png"
export const EMAIL_BUTTON_DEFAULT_TEXT_COLOR = "#ffffff"
export const BRAND_COLOR = "#DE3439"
export const DEFAULT_WORKSPACE = "Testlify"
export const CANDIDATE_REMINDER_EMAIL = "d-005eb7de947b480fb0a25f3eeef0d77b"
export const CANDIDATE_REMINDER_EMAIL_VERSION_ID = "7dda2dba-9124-47ab-9b02-22529e7d7def"
export const SMTP_INVALID_CREDENTIAL_TEMPLATE_ID = "d-3b2a254de6c042deb8fe65d296ddf6da"
export const SENDGRID_ASSESSMENT_INVITE_CUSTOM_EMAIL = "d-f1dc418cffdc41db9dc4117b58b2ef9e"
export const SENDGRID_ASSESSMENT_INVITE_CUSTOM_VERSION_ID = "fe667e3b-435f-4de4-b283-f1b3c3d2fffa"
export const CANDIDATE_STATUS_TEST_RESULT_SENT_BY_SYSTEM = "6474bab294f3ee77ed0a66fa"
export const ENCRYPTION_SECRET_KEY = "E53KAONBVF"

export const WEBHOOK_EVENTS = Object.freeze({
  ASSESSMENT_CREATED: "created",
  ASSESSMENT_UPDATED: "updated",
  ASSESSMENT_DELETED: "deleted",
  ASSESSMENT_ARCHIVED: "archived",
  CANDIDATE_STATUS_CHANGED: "candidateStatusChanged",
  ASSESSMENT_COMPLETED: "completed",
  CANDIDATE_INVITED: "invited",
  CANDIDATE_DISQUALIFIED: "disqualified",
  ASSESSMENT_INPROGRESS: "in_progress",
  INVITATION_EXPIRED: "invitation_expired",
  CANDIDATE_ENROLLED: "enrolled",
  CANDIDATE_REJECTED: "rejected",
})

export const RESELLER_ACCOUNT = {
  JOIN: "join.com",
  TESTLIFY: "testlify.com"
}

export const ASSESSMENT_CANDIDATE_STATUS = {
  INVITED: "INVITED",
  ENROLLED: "ENROLLED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  REJECTED: "REJECTED",
  DISQUALIFIED: "DISQUALIFIED",
  INVITATION_EXPIRED: 'INVITATION_EXPIRED',
  REMINDER_SENT_BY_SYSTEM: 'REMINDER_SENT_BY_SYSTEM',
  REMINDER_SENT_MANUALLY: 'REMINDER_SENT_MANUALLY',
  TEST_RESULT_SENT_BY_SYSTEM: 'TEST_RESULT_SENT_BY_SYSTEM',
  TEST_RESULT_SENT_MANUALLY: 'TEST_RESULT_SENT_MANUALLY',
  TERMINATED: "TERMINATED",

}

export const WHITE_LABELED_PLAN = [
  "white-labeled-usd-yearly",
  "white-labeled-inr-yearly",
  "white-labeled-gbp-yearly",
  "white-labeled-inr-monthly",
  "white-labeled-usd-monthly",
  "white-labeled-gbp-monthly",
]

export const EmailTypesMultiLingual = {
  OTP_PUSH_EMPLOYER: "OTP_PUSH_EMPLOYER",
  ASSESSMENT_INVITE: "ASSESSMENT_INVITE",
  CANDIDATE_REJECT: "CANDIDATE_REJECT",
  CANDIDATE_RESULT: "CANDIDATE_RESULT",
  ASSESSMENT_COMPLETED_EMPLOYER: "ASSESSMENT_COMPLETED_EMPLOYER",
  ASSESSMENT_COMPLETED_CANDIDATE: "ASSESSMENT_COMPLETED_CANDIDATE",
  ASSESSMENT_REMINDER: "ASSESSMENT_REMINDER",
  OTP_PUSH_CANDIDATE: "OTP_PUSH_CANDIDATE",
  CSV_EMAIL_TO_EMPLOYER: "CSV_EMAIL_TO_EMPLOYER",
  WORKSPACE_INVITATION: "WORKSPACE_INVITATION",
  ASSESSMENT_REPORT_EMAIL: "ASSESSMENT_REPORT_EMAIL",
}

export const JSON_SCHEMA = Object.freeze({
  MCQ: {
    "$schema": "http://json-schema.org/draft-07/schema#",
    "title": "Generated schema for Root",
    "type": "object",
    "properties": {
      "questions": {
        "type": "array",
        "items": {
          "type": "object",
          "properties": {
            "question": {
              "type": "string"
            },
            "options": {
              "type": "array",
              "items": {
                "type": "object",
                "properties": {
                  "description": {
                    "type": "string"
                  },
                  "isCorrect": {
                    "type": "boolean"
                  }
                },
                "required": [
                  "description",
                  "isCorrect"
                ]
              }
            },
            "difficultyLevel": {
              "type": "string",
              "enum": [
                "BEGINNER",
                "INTERMEDIATE",
                "ADVANCED"
              ]
            }
          },
          "required": [
            "question",
            "options",
            "difficultyLevel"
          ]
        }
      }
    },
    "required": [
      "questions"
    ]
  },
  TEST_LIBRARY: {
    "$schema": "http://json-schema.org/draft-04/schema#",
    "type": "object",
    "properties": {
      "title": {
        "type": "string"
      },
      "overview": {
        "type": "string"
      },
      "description": {
        "type": "string"
      },
      "relevancy": {
        "type": "string"
      },
      "skills": {
        "type": "array",
        "items": [
          {
            "type": "object",
            "properties": {
              "description": {
                "type": "string"
              }
            },
            "required": [
              "description"
            ]
          },
          {
            "type": "object",
            "properties": {
              "description": {
                "type": "string"
              }
            },
            "required": [
              "description"
            ]
          },
          {
            "type": "object",
            "properties": {
              "description": {
                "type": "string"
              }
            },
            "required": [
              "description"
            ]
          },
          {
            "type": "object",
            "properties": {
              "description": {
                "type": "string"
              }
            },
            "required": [
              "description"
            ]
          }
        ]
      }
    },
    "required": [
      "title",
      "overview",
      "description",
      "relevancy",
      "skills"
    ]
  },
  QUESTION_REPHRASE: {
    "type": "object",
    "properties": {
      "question": {
        "type": "string"
      },
      "options": {
        "type": "array",
        "items": [
          {
            "type": "object",
            "properties": {
              "description": {
                "type": "string"
              }
            },
            "required": [
              "description"
            ]
          },
          {
            "type": "object",
            "properties": {
              "description": {
                "type": "string"
              }
            },
            "required": [
              "description"
            ]
          },
          {
            "type": "object",
            "properties": {
              "description": {
                "type": "string"
              }
            },
            "required": [
              "description"
            ]
          },
          {
            "type": "object",
            "properties": {
              "description": {
                "type": "string"
              }
            },
            "required": [
              "description"
            ]
          },
          {
            "type": "object",
            "properties": {
              "description": {
                "type": "string"
              },
              "isCorrect": {
                "type": "boolean"
              }
            },
            "required": [
              "description",
              "isCorrect"
            ]
          }
        ]
      }
    },
    "required": [
      "question",
      "options"
    ]
  }
});

export const AI_ASSESSMENT_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
}

export const BULL_MQ_JOBS = {
  AUTOMATIC_REMINDER: 'sendAutomaticReminder'
}

export const PAY_PER_PLAN_PRICES = {
  INR_CANDIDATE_INVITE: 249,
  USD_CANDIDATE_INVITE: 5,
}

export const AI_ASSESSMENT_QUESTION_SEQUENCE = [
  QuestionnaireClassification.SINGLE_SELECT,
  QuestionnaireClassification.WRITTEN_ANSWER,
  QuestionnaireClassification.VIDEO_ANSWER
]

export const WEBHOOK_TYPE = {
  CANDIDATE: "candidate",
  ASSESSMENT: "assessment"
}

export const ADD_ON_TYPE = {
  WHITELABEL: "White-Label-Addon",
  INTEGRATION: "Integration-Addon"
}

export const QUESTIONAIRE_CLASSIFICATION = {
  MULTI_SELECT: "MULTI_SELECT",
  SINGLE_SELECT: "SINGLE_SELECT",
  SHORT_ANSWER: "SHORT_ANSWER",
  WRITTEN_ANSWER: "WRITTEN_ANSWER",
  VIDEO_ANSWER: "VIDEO_ANSWER",
  CODING_ANSWER: "CODING_ANSWER",
  TYPING_TEST: "TYPING_TEST",
  FILE_UPLOAD: "FILE_UPLOAD",
  AUDIO_ANSWER: "AUDIO_ANSWER",
  DOCUMENT: "DOCUMENT",
  SLIDES: "SLIDES",
  SPREADSHEET: "SPREADSHEET",
  CHOICE_ANSWER: "CHOICE_ANSWER",
  NUMBER: "NUMBER",
  FILL_IN_BLANK: "FILL_IN_BLANK",
  DATE_ANSWER: "DATE_ANSWER",
  RANKING: "RANKING",
  DROPDOWN: "DROPDOWN"
}

export const TEST_LIBRARY_TYPE = {
  CODING_TEST: "Coding test",
}

export const EXTRA_TEST_LIBRARY = ["psychometric"]

export const PAY_PER_PLANS = {
  PAY_AS_YOU_GBP_MONTHLY: "pay-as-you-go-GBP-Monthly",
  PAY_AS_YOU_USD_MONTHLY: "pay-as-you-go-USD-Monthly",
  PAY_AS_YOU_INR_MONTHLY: "pay-as-you-go-INR-Monthly"
}

export const CREDIT_MODEL_TYPE = {
  PREPAID: "prepaid",
  POSTPAID: "postpaid",
  PREPAID_POSTPAID: "prepaid_postpaid"
}

export const DEFAULT_GRADING = [
  {
    "name": "NOT_SUITABLE",
    "min": 0,
    "max": 25,
    "color": "#CF4747"
  },
  {
    "name": "BEGINNER",
    "min": 26,
    "max": 40,
    "color": "#F07A44"
  },
  {
    "name": "INTERMEDIATE",
    "min": 41,
    "max": 60,
    "color": "#F49B3E"
  },
  {
    "name": "EXPERIENCED",
    "min": 61,
    "max": 75,
    "color": "#4D9E6F"
  },
  {
    "name": "EXPERT",
    "min": 76,
    "max": 90,
    "color": "#499FF7"
  },
  {
    "name": "PROFICIENT",
    "min": 91,
    "max": 100,
    "color": "#9B43BC"
  }
]

export const CustomFieldTypes = {
  TEXT: "TEXT",
  NUMBER: "NUMBER",
  FILE_UPLOAD: "FILE_UPLOAD",
  DROPDOWN: "DROPDOWN",
  EMAIL: "EMAIL",
  URL: "URL",
}

export const CustomFieldStatus = {
  REQUIRED: "REQUIRED",
  OPTIONAL: "OPTIONAL",
}

export const CustomFieldVisibility = {
  "VISIBLE": "VISIBLE",
  "HIDDEN": "HIDDEN",
}

export const ADDITIONAL_INFO_SETTING = [
  {
    "label": "Email",
    "type": "EMAIL",
    "status": "REQUIRED",
    "visibility": "VISIBLE",
    "isDefault": true
  },
  {
    "label": "First name",
    "type": "TEXT",
    "status": "REQUIRED",
    "visibility": "VISIBLE",
    "isDefault": true
  },
  {
    "label": "Last name",
    "type": "TEXT",
    "status": "REQUIRED",
    "visibility": "VISIBLE",
    "isDefault": true
  },
  {
    "label": "Phone number",
    "type": "NUMBER",
    "status": "OPTIONAL",
    "visibility": "VISIBLE",
    "isDefault": true
  }
]

export const PostMarkTemplateAlias = {
  ASSESSMENT_REMINDER: 'assessment-reminder',
  CUSTOM_EMAIL: 'custom-email',
  CANDIDATE_REPORT: 'assessment-result',
  ASSESSMENT_COMPLETED_EMPLOYER: 'assessment-completed-employer',
  ASSESSMENT_COMPLETED_CANDIDATE: 'assessment-completed-candidate',
  CANDIDATE_OTP: 'candidate-otp',
  DELETE_WORKSPACE: 'delete-workspace'
}

export const CustomQuestionTimerType = {
  DEFAULT: "DEFAULT",
  CUSTOM: "CUSTOM",
  NO_LIMIT: "NO_LIMIT"
}

export const CodingLanguageType = {
  PROGRAMMING: "programming",
  DATABASE: "database",
}

export const CodingFileType = {
  SINGLE_FILE: "SINGLE_FILE",
  MULTI_FILE: "MULTI_FILE"
}

export const DEFAULT_REPORT_SETTING = {
  "candidateInfo": true,
  "performanceAnalytics": true,
  "questionAsked": true,
  "expectedAnswer": false,
  "proctoringInfo": false,
  "statusLogs": true
}

export const ANALYTICS_FILTER_TYPE = Object.freeze({
  ASSESSMENT_ID: "ASSESSMENT_ID",
  FROM_DATE: "FROM_DATE",
  TO_DATE: "TO_DATE",
})

export const PHONE_CODES = {
  "United States": "1",
  "Canada": "1",
  "United Kingdom": "44",
  "Australia": "61",
  "India": "91",
  "Germany": "49",
  "France": "33",
  "Italy": "39",
  "Brazil": "55",
  "South Africa": "27",
  "Japan": "81",
  "China": "86",
  "Mexico": "52",
  "Russia": "7",
  "South Korea": "82",
  "Saudi Arabia": "966",
  "United Arab Emirates": "971",
  "Netherlands": "31",
  "Spain": "34",
  "Turkey": "90",
  "Portugal": "351"
};

export const TEST_LIBRARY_DIFFICULTY_LEVEL = {
  BEGINNER: "BEGINNER",
  INTERMEDIATE: "INTERMEDIATE",
  ADVANCED: "ADVANCED",
  EXPERT: "EXPERT",
  NOT_APPLICABLE: "NOT_APPLICABLE",
}

export const QUESTION_DIFFICULTY_LEVEL = {
  BEGINNER: "BEGINNER",
  INTERMEDIATE: "INTERMEDIATE",
  ADVANCED: "ADVANCED",
  // EXPERT: "EXPERT",
  NOT_APPLICABLE: "NOT_APPLICABLE",
}

export const SCORE_LEVEL = {
  OPTION : "OPTION",
  QUESTION : "QUESTION",
  TEST_CASE : "TEST_CASE",
  CRITERIA : "CRITERIA",
}

export const EMPLOYER_TRANSLATION_SCHEMA = {
  "$schema": "http://json-schema.org/draft-07/schema#",
  "title": "TopLevelAttributesSchema",
  "type": "object",
  "properties": {
    "add_questions": { "type": "object" },
    "company_test_library": { "type": "object" },
    "archive_assessment_modal": { "type": "object" },
    "assessment_setting_edit": { "type": "object" },
    "assessment_test_library": { "type": "object" },
    "webhooks_modal": { "type": "object" },
    "all_questions": { "type": "object" },
    "promote_questions": { "type": "object" },
    "notification_header": { "type": "object" },
    "biling_info": { "type": "object" },
    "placeholder": { "type": "object" },
    "choice_question": { "type": "object" },
    "company_test_audio": { "type": "object" },
    "company_test_docs": { "type": "object" },
    "company_test_file_upload": { "type": "object" },
    "company_test_open_ended": { "type": "object" },
    "company_test_video": { "type": "object" },
    "company_test_date_que_modal": { "type": "object" },
    "choose_plan": { "type": "object" },
    "number_question": { "type": "object" },
    "ranking_modal": { "type": "object" },
    "dropdown_option_modal": { "type": "object" },
    "all_tests": { "type": "object" },
    "clone_assessment_modal": { "type": "object" },
    "edit_assessment_modal": { "type": "object" },
    "file_upload_modal": { "type": "object" },
    "generic_test": { "type": "object" },
    "invite_member_form": { "type": "object" },
    "login_otp": { "type": "object" },
    "profile_action_menu": { "type": "object" },
    "multiple_company_test": { "type": "object" },
    "single_select_que_modal": { "type": "object" },
    "upload_favicon": { "type": "object" },
    "upload_introduction_img": { "type": "object" },
    "upload_logo": { "type": "object" },
    "audio_que_modal": { "type": "object" },
    "typing_test_que_modal": { "type": "object" },
    "constants_text": { "type": "object" },
    "global_search": { "type": "object" },
    "hire_the_best": { "type": "object" },
    "industry_translation": { "type": "object" },
    "intermediat_login": { "type": "object" },
    "interview_teaser": { "type": "object" },
    "invite_warning_msg_modal": { "type": "object" },
    "invitee_registration_page": { "type": "object" },
    "is_scorable": { "type": "object" },
    "label_text": { "type": "object" },
    "link_integration_modal": { "type": "object" },
    "manage_limit_page": { "type": "object" },
    "notification_modal": { "type": "object" },
    "notification_panel": { "type": "object" },
    "notification_tab": { "type": "object" },
    "open_ended_que_modal": { "type": "object" },
    "payment_confirmation": { "type": "object" },
    "payment_info": { "type": "object" },
    "permissions": { "type": "object" },
    "phone_no_verification": { "type": "object" },
    "phone_number": { "type": "object" },
    "plan_upgrade_notification": { "type": "object" },
    "practical_question": { "type": "object" },
    "public_invite": { "type": "object" },
    "qualify_que": { "type": "object" },
    "question": { "type": "object" },
    "quick_tip_modal": { "type": "object" },
    "ratings_que": { "type": "object" },
    "reject_candidate_modal": { "type": "object" },
    "rolesmodal": { "type": "object" },
    "select_custom_lang_modal": { "type": "object" },
    "select_language_modal": { "type": "object" },
    "send_bulk_reminder_modal": { "type": "object" },
    "send_reminder_modal": { "type": "object" },
    "send_result_modal": { "type": "object" },
    "setting_menus": { "type": "object" },
    "shareable_link_modal": { "type": "object" },
    "signin_page": { "type": "object" },
    "signup_page": { "type": "object" },
    "smtp_configure_form": { "type": "object" },
    "sso_config": { "type": "object" },
    "subscription_expired": { "type": "object" },
    "support_tab": { "type": "object" },
    "switch_back_smtp": { "type": "object" },
    "switch_back_support_modal": { "type": "object" },
    "switch_plan": { "type": "object" },
    "switch_workspace": { "type": "object" },
    "talents_tab": { "type": "object" },
    "team_list": { "type": "object" },
    "team_member_dropdown": { "type": "object" },
    "test_details_modal": { "type": "object" },
    "tests_page": { "type": "object" },
    "toast_ui_editor": { "type": "object" },
    "unsaved_changes_modal": { "type": "object" },
    "update_card": { "type": "object" },
    "upgrade_notification": { "type": "object" },
    "upload_database": { "type": "object" },
    "upload_welcome_video": { "type": "object" },
    "view_profile": { "type": "object" },
    "webhook_tab": { "type": "object" },
    "welcome_ceo_modal": { "type": "object" },
    "welcome_videos": { "type": "object" },
    "white_label_banner": { "type": "object" },
    "white_label_trial_modal": { "type": "object" },
    "widget_rating_panel": { "type": "object" },
    "workspace_landing_page": { "type": "object" },
    "correction_req_modal": { "type": "object" },
    "bulk_delete_schedule_invite_modal": { "type": "object" },
    "bulk_schedule_invite_modal": { "type": "object" },
    "bulk_schedule_invitees_list_modal": { "type": "object" },
    "copy_company_test_modal": { "type": "object" },
    "shortlist_candidate_modal": { "type": "object" },
    "skill_progress_bar": { "type": "object" },
    "saml_sso": { "type": "object" },
    "toast_notifications": { "type": "object" },
    "access_token": { "type": "object" },
    "bulk_delete_assessment_modal": { "type": "object" },
    "discover_card": { "type": "object" },
    "onedrive": { "type": "object" },
    "subscription_inactive_modal": { "type": "object" },
    "copy_assessment_settings_modal": { "type": "object" },
    "confirm_test_name_modal": { "type": "object" },
    "schedule_invite": { "type": "object" },
    "criteria": { "type": "object" },
    "question_config_modal": { "type": "object" },
    "generate_assessment_insight": { "type": "object" },
    "audit_logs": { "type": "object" },
    "score_weight": { "type": "object" },
    "edit_access": { "type": "object" },
    "bulk_change_stage": { "type": "object" },
    "comment": { "type": "object" },
    "comment_delete_modal": { "type": "object" }
  },
  "required": [
    "add_questions",
    "company_test_library",
    "archive_assessment_modal",
    "assessment_setting_edit",
    "assessment_test_library",
    "webhooks_modal",
    "all_questions",
    "promote_questions",
    "notification_header",
    "biling_info",
    "placeholder",
    "choice_question",
    "company_test_audio",
    "company_test_docs",
    "company_test_file_upload",
    "company_test_open_ended",
    "company_test_video",
    "company_test_date_que_modal",
    "choose_plan",
    "number_question",
    "ranking_modal",
    "dropdown_option_modal",
    "all_tests",
    "clone_assessment_modal",
    "edit_assessment_modal",
    "file_upload_modal",
    "generic_test",
    "invite_member_form",
    "login_otp",
    "profile_action_menu",
    "multiple_company_test",
    "single_select_que_modal",
    "upload_favicon",
    "upload_introduction_img",
    "upload_logo",
    "audio_que_modal",
    "typing_test_que_modal",
    "constants_text",
    "global_search",
    "hire_the_best",
    "industry_translation",
    "intermediat_login",
    "interview_teaser",
    "invite_warning_msg_modal",
    "invitee_registration_page",
    "is_scorable",
    "label_text",
    "link_integration_modal",
    "manage_limit_page",
    "notification_modal",
    "notification_panel",
    "notification_tab",
    "open_ended_que_modal",
    "payment_confirmation",
    "payment_info",
    "permissions",
    "phone_no_verification",
    "phone_number",
    "plan_upgrade_notification",
    "practical_question",
    "public_invite",
    "qualify_que",
    "question",
    "quick_tip_modal",
    "ratings_que",
    "reject_candidate_modal",
    "rolesmodal",
    "select_custom_lang_modal",
    "select_language_modal",
    "send_bulk_reminder_modal",
    "send_reminder_modal",
    "send_result_modal",
    "setting_menus",
    "shareable_link_modal",
    "signin_page",
    "signup_page",
    "smtp_configure_form",
    "sso_config",
    "subscription_expired",
    "support_tab",
    "switch_back_smtp",
    "switch_back_support_modal",
    "switch_plan",
    "switch_workspace",
    "talents_tab",
    "team_list",
    "team_member_dropdown",
    "test_details_modal",
    "tests_page",
    "toast_ui_editor",
    "unsaved_changes_modal",
    "update_card",
    "upgrade_notification",
    "upload_database",
    "upload_welcome_video",
    "view_profile",
    "webhook_tab",
    "welcome_ceo_modal",
    "welcome_videos",
    "white_label_banner",
    "white_label_trial_modal",
    "widget_rating_panel",
    "workspace_landing_page",
    "correction_req_modal",
    "bulk_delete_schedule_invite_modal",
    "bulk_schedule_invite_modal",
    "bulk_schedule_invitees_list_modal",
    "copy_company_test_modal",
    "shortlist_candidate_modal",
    "skill_progress_bar",
    "saml_sso",
    "toast_notifications",
    "access_token",
    "bulk_delete_assessment_modal",
    "discover_card",
    "onedrive",
    "subscription_inactive_modal",
    "copy_assessment_settings_modal",
    "confirm_test_name_modal",
    "schedule_invite",
    "criteria",
    "question_config_modal",
    "generate_assessment_insight",
    "audit_logs",
    "score_weight",
    "edit_access",
    "bulk_change_stage",
    "comment",
    "comment_delete_modal"
  ],
  "additionalProperties": true
}

export const CANDIDATE_TRANSLATION_SCHEMA = {
  "$schema": "http://json-schema.org/draft-07/schema#",
  "title": "Assessment Platform Schema",
  "type": "object",
  "properties": {
    "feedback_page": {
      "type": "object"
    },
    "signup_page": {
      "type": "object"
    },
    "camera_access_page": {
      "type": "object"
    },
    "test_interval_page": {
      "type": "object"
    },
    "assessment_q_a_page": {
      "type": "object"
    },
    "overview": {
      "type": "object"
    },
    "test_intro_page": {
      "type": "object"
    },
    "file_upload_preview": {
      "type": "object"
    },
    "practical_question": {
      "type": "object"
    },
    "test_library": {
      "type": "object"
    },
    "camera_mic_trouble": {
      "type": "object"
    },
    "error_page": {
      "type": "object"
    },
    "accommodation": {
      "type": "object"
    },
    "coding_switch_modal": {
      "type": "object"
    },
    "otp_page": {
      "type": "object"
    },
    "assessment_already_submitted": {
      "type": "object"
    },
    "assessment_unqualified_page": {
      "type": "object"
    },
    "feedback_thanks_page": {
      "type": "object"
    },
    "thanks_page": {
      "type": "object"
    },
    "skip_question_modal": {
      "type": "object"
    },
    "widget_video_question_page": {
      "type": "object"
    },
    "assessment_statistics_page": {
      "type": "object"
    },
    "qualifying_page": {
      "type": "object"
    },
    "intro_test_type": {
      "type": "object"
    },
    "assessment_result": {
      "type": "object"
    },
    "missing_access": {
      "type": "object"
    },
    "correction_req_modal": {
      "type": "object"
    },
    "skip_test_modal": {
      "type": "object"
    },
    "qualifying_q_a_page": {
      "type": "object"
    },
    "mobile_app": {
      "type": "object"
    },
    "coding_confirm_modal": {
      "type": "object"
    },
    "fullscreen": {
      "type": "object"
    },
    "share_screen_details_page": {
      "type": "object"
    },
    "browser_compatibility": {
      "type": "object"
    },
    "disqualified_page": {
      "type": "object"
    },
    "screen_sharing": {
      "type": "object"
    },
    "notification_banner": {
      "type": "object"
    },
    "camera_mic_test": {
      "type": "object"
    },
    "preview_tabs": {
      "type": "object"
    },
    "constants_text": {
      "type": "object"
    },
    "time": {
      "type": "object"
    },
    "invitation_expired": {
      "type": "object"
    },
    "assessment_qualified_page": {
      "type": "object"
    },
    "widget_test_progress_page": {
      "type": "object"
    },
    "error": {
      "type": "object"
    },
    "browser_outdated_page": {
      "type": "object"
    },
    "restricted_access": {
      "type": "object"
    },
    "audio_question_page": {
      "type": "object"
    },
    "report_issue_button": {
      "type": "object"
    },
    "countdown_page": {
      "type": "object"
    },
    "time_remaining_modal": {
      "type": "object"
    },
    "mobile_view_warning_screen": {
      "type": "object"
    },
    "clear_practical_answer_modal": {
      "type": "object"
    },
    "token_unavailable": {
      "type": "object"
    },
    "mobile_orientation": {
      "type": "object"
    },
    "unsubscribe_reminder_email": {
      "type": "object"
    },
    "biling_info": {
      "type": "object"
    },
    "practice_assessment_banner": {
      "type": "object"
    },
    "clipboard_permission": {
      "type": "object"
    },
    "credits_expired": {
      "type": "object"
    },
    "mandate_cam_mic": {
      "type": "object"
    },
    "video_audio_question_page": {
      "type": "object"
    },
    "proctoring": {
      "type": "object"
    },
    "restrict_ai": {
      "type": "object"
    },
    "re-attempt_modal": {
      "type": "object"
    },
    "verify_password": {
      "type": "object"
    },
    "end_assessment_modal": {
      "type": "object"
    },
    "caution_msg": {
      "type": "object"
    },
    "Internet_connection": {
      "type": "object"
    },
    "clipboard_access": {
      "type": "object"
    },
    "dual_proctoring": {
      "type": "object"
    },
    "end_proctoring_prompt": {
      "type": "object"
    },
    "multi_screen": {
      "type": "object"
    },
    "onedrive": {
      "type": "object"
    },
    "grid_modals": {
      "type": "object"
    },
    "mandatory_question_modal": {
      "type": "object"
    },
    "picture_based_response": {
      "type": "object"
    },
    "stop_conversation_modal": {
      "type": "object"
    },
    "demographic_feedback_page": {
      "type": "object"
    },
    "skip_demographic_feedback_modal": {
      "type": "object"
    },
  },
  "required": [
    "feedback_page",
    "signup_page",
    "camera_access_page",
    "test_interval_page",
    "assessment_q_a_page",
    "overview",
    "test_intro_page",
    "file_upload_preview",
    "practical_question",
    "test_library",
    "camera_mic_trouble",
    "error_page",
    "accommodation",
    "coding_switch_modal",
    "otp_page",
    "assessment_already_submitted",
    "assessment_unqualified_page",
    "feedback_thanks_page",
    "thanks_page",
    "skip_question_modal",
    "widget_video_question_page",
    "assessment_statistics_page",
    "qualifying_page",
    "intro_test_type",
    "assessment_result",
    "missing_access",
    "correction_req_modal",
    "skip_test_modal",
    "qualifying_q_a_page",
    "mobile_app",
    "coding_confirm_modal",
    "fullscreen",
    "share_screen_details_page",
    "browser_compatibility",
    "disqualified_page",
    "screen_sharing",
    "notification_banner",
    "camera_mic_test",
    "preview_tabs",
    "constants_text",
    "time",
    "invitation_expired",
    "assessment_qualified_page",
    "widget_test_progress_page",
    "error",
    "browser_outdated_page",
    "restricted_access",
    "audio_question_page",
    "report_issue_button",
    "countdown_page",
    "time_remaining_modal",
    "mobile_view_warning_screen",
    "clear_practical_answer_modal",
    "token_unavailable",
    "mobile_orientation",
    "unsubscribe_reminder_email",
    "biling_info",
    "practice_assessment_banner",
    "clipboard_permission",
    "credits_expired",
    "mandate_cam_mic",
    "video_audio_question_page",
    "proctoring",
    "restrict_ai",
    "re-attempt_modal",
    "verify_password",
    "end_assessment_modal",
    "caution_msg",
    "Internet_connection",
    "clipboard_access",
    "dual_proctoring",
    "end_proctoring_prompt",
    "multi_screen",
    "onedrive",
    "grid_modals",
    "mandatory_question_modal",
    "picture_based_response",
    "stop_conversation_modal",
    "demographic_feedback_page",
    "skip_demographic_feedback_modal",
  ]
}

export const MergeDevKeyStatus = {
  ACTIVE : "ACTIVE",
  EXHAUSTED : "EXHAUSTED",
  PENDING : "PENDING",
}

// export default {ANALYTICS_FILTER_TYPE, ROLE_ADMIN, EXTRA_TEST_LIBRARY, TEST_LIBRARY_DIFFICULTY_LEVEL, SMART_TEST_LIBRARY_SUBTYPE, CULTURAL_FIT_TEST_LIBRARY_SUBTYPE, DISC_TEST_LIBRARY_SUBTYPE, TEST_LIBRARY_TYPE, AI_ASSESSMENT_STATUS, JSON_SCHEMA, ROLE_AUTHOR, ROLE_MARKETING, ROLE_DATA, ACTIONS, QuestionnaireClassification, TextFormat, QUESTION_DIFFICULTY_LEVEL, AssessmentStatus, QUESTION_STATUS, TESTLIBRARY_STATUS, ChargeBeePlans, SUPPORT_EMAIL, LOGO_URL, EMAIL_BUTTON_DEFAULT_TEXT_COLOR, BRAND_COLOR, ENCRYPTION_SECRET_KEY, CANDIDATE_REMINDER_EMAIL, CANDIDATE_REMINDER_EMAIL_VERSION_ID, SMTP_INVALID_CREDENTIAL_TEMPLATE_ID, SENDGRID_ASSESSMENT_INVITE_CUSTOM_EMAIL, SENDGRID_ASSESSMENT_INVITE_CUSTOM_VERSION_ID, EmailTypes, DEFAULT_WORKSPACE, TemplateButtonStyle, WEBHOOK_EVENTS, WHITE_LABELED_PLAN, EmailTypesMultiLingual, ADD_ON_TYPE, ASSESSMENT_CANDIDATE_STATUS, RESELLER_ACCOUNT, ASSESSMENT_LIBRARY_STATUS, QUESTIONAIRE_CLASSIFICATION, WEBHOOK_TYPE, PAY_PER_PLAN_PRICES, PAY_PER_PLANS, AI_ASSESSMENT_QUESTION_SEQUENCE, CREDIT_MODEL_TYPE, PostMarkTemplateAlias, DEFAULT_GRADING, CustomFieldTypes, CustomFieldStatus, CustomFieldVisibility, ADDITIONAL_INFO_SETTING, CustomQuestionTimerType, DEFAULT_REPORT_SETTING, CodingLanguageType, CANDIDATE_STATUS_TEST_RESULT_SENT_BY_SYSTEM, DEFAULT_DOMAIN, CodingFileType, DEFAULT_LANGUAGE, USER_TYPE, ASSESSMENT_REMINDER, PHONE_CODES }
