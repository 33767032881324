import {ApiClient} from 'adminjs'
import React from "react";
import {withTheme} from 'styled-components'
import {FormGroup, Label} from '@adminjs/design-system'
const api = new ApiClient()

const CustomValueView = (props) => {
    const {property, resource, record} = props
    const [values, setValues] = React.useState()
    var actionFunc, label, resourceId= '';
    console.log(props);

    if(property.path == 'skillId'){
        resourceId = 'TestLibrarySkillData';
        actionFunc = 'fetchSkills';
        label = 'Skill Name';
    }

    React.useEffect(()=>{
        const testLibraryId = record.params.testLibraryId;
        const fetchSkillDescription = async () => {
            const response = await api.resourceAction({
                resourceId: resourceId,
                actionName: actionFunc,
                data: { testLibraryId: testLibraryId }
            });

            const skillId = record.params.skillId;
            const skillDescriptions = response.data.skills.map(skill => (skill._id.toString() === skillId) ? skill.description : "" );
            setValues(skillDescriptions);
        };

        fetchSkillDescription();
    },[])
    return (
        <div>
            <FormGroup>
            {props.where == "show" ? (<Label variant="light">{label}</Label>) : ''}
            {values}
            </FormGroup>
        </div>
    )
}
export default withTheme(CustomValueView)
