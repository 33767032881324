import React from 'react'
import {FormGroup, Label, InputGroup, FormMessage} from '@adminjs/design-system';
import {ApiClient} from 'adminjs';
const api = new ApiClient();


const MarkDownEditor = (props) => {

    const {onChange, record, property} = props

    const [toggle, setToggle] = React.useState(false)

    const label = property.label || '';
    const isRequired = property.isRequired || false;
    const path = property.path || '';
    const value = record.params[path];
    const selector = path.replaceAll('.', '-');
    let loadingScreen;
    const loader = (type) => {
        loadingScreen = document.createElement('div');
        loadingScreen.style.position = 'fixed';
        loadingScreen.style.top = '0';
        loadingScreen.style.left = '0';
        loadingScreen.style.width = '100%';
        loadingScreen.style.height = '100%';
        loadingScreen.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
        loadingScreen.style.display = 'flex';
        loadingScreen.style.justifyContent = 'center';
        loadingScreen.style.alignItems = 'center';
        loadingScreen.style.fontSize = '24px';
        loadingScreen.style.color = '#333';
        loadingScreen.style.borderRadius = '5px';

        const loadingAnimation = document.createElement('style');
        loadingAnimation.textContent = `
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }
  div.loading-screen {
    animation: pulse 1s ease-in-out infinite;
  }
`;
        document.head.appendChild(loadingAnimation);
        loadingScreen.classList.add('loading-screen');
        switch (type) {
            case 'audio':
                loadingScreen.innerHTML = 'Uploading audio...';
                break
            case 'video':
                loadingScreen.innerHTML = "Uploading Video...."
                break
            case 'image':
                loadingScreen.innerHTML = "Uploading Image/GIF..."
                break
        }
        document.body.appendChild(loadingScreen);
    }
    const addImage = async () => {
        const imageFile = document.getElementById('imageUrl').files[0];
        loader('image')
        const file = await convertBase64(imageFile);
        const type = file.split(';')[0].split(':')[1].split('/')[0];
        const mime = file.split(';')[0].split(':')[1].split('/')[1];
        if (type === 'image') {
            const response = await api.resourceAction(
                {
                    resourceId: 'Question',
                    actionName: 'imageUpload',
                    data: {new: file, mime: mime}
                }
            );
            document.getElementById('imageUrl').value = '';
            loadingScreen.style.display = 'none';
            return response;
        } else {
            alert('Wrong Input Type');
        }
    };

    const addAudio = async () => {
        const videoFile = document.getElementById('audioUrl').files[0];
        if(videoFile === undefined){
            alert('Please add audio file.');
            return videoFile;
        }
        else{
            loader('audio')
            const file = await convertBase64(videoFile);
            const type = file.split(';')[0].split(':')[1].split('/')[0];
            const mime = file.split(';')[0].split(':')[1].split('/')[1];
            if (type === 'audio') {
                const response = await api.resourceAction(
                    {
                        resourceId: 'Question',
                        actionName: 'audioUpload',
                        data: {new: file, mime: mime === 'mpeg' ? 'mp3' : mime}
                    }
                )
                document.getElementById("audioUrl").value = "";
                loadingScreen.style.display = 'none';
                return response;
            } else {
                alert('Wrong Input Type');
            }
        }
    }

    //Only for while we adding question
    const addAudioPlaybackLimit = async () => {
        const audiolimit = document.getElementById('playbacklimit').value;
        return audiolimit;
    }

    const addAudioautoplay = async () => {
        const autoplaychkbox = document.getElementById('autoplaychkbox');
        return ((autoplaychkbox.checked) ? true : false);
    }

    const addVideo = async () => {
        try {
            const videoFile = document.getElementById('videoUrl').files[0];
            const fileSize = videoFile.size / (1024 * 1024); // in MB
            if (fileSize > 50) {
                alert('File size must be less than 50MB');
                return;
            }
            loader('video')
            const file = await convertBase64(videoFile);
            const type = file.split(';')[0].split(':')[1].split('/')[0];
            const mime = file.split(';')[0].split(':')[1].split('/')[1];
            if (type === 'video') {
                const response = await api.resourceAction(
                    {
                        resourceId: 'Question',
                        actionName: 'videoUpload',
                        data: {new: file, mime: mime}
                    }
                )
                document.getElementById("videoUrl").value = "";
                loadingScreen.style.display = 'none';
                return response;
            } else {
                alert('Wrong Input Type');
            }
        } catch (error) {
            console.error(error);
            loadingScreen.style.display = 'none';

        }
        finally {
            loadingScreen.style.display = 'none';
        }
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    React.useEffect(() => {
        if (typeof toastui !== 'undefined') {
            const editor = new toastui.Editor({
                el: document.querySelector('#editor-' + selector),
                height: '300px',
                usageStatistics: false,
                initialValue: value,
                initialEditType: 'wysiwyg',
                hideModeSwitch: true,
                autofocus: false,
                useDefaultHTMLSanitizer: true,
                toolbarItems: [
                    ['heading', 'bold', 'italic', 'strike'],
                    ['hr', 'quote'],
                    ['ul', 'ol', 'task', 'indent', 'outdent'],
                    ['table', 'link'],
                    ['code', 'codeblock'],
                ],
                events: {
                    blur: function () {
                        const md = editor.getMarkdown()
                        onChange(path, md)
                    }
                },
                previewStyle: 'vertical'
            });

            //image toastui toolbar UI
            const imageContainer = document.createElement('form');
            imageContainer.name = 'myImageForm';
            imageContainer.method = 'POST';

            const imageInputDiv = document.createElement('div');
            const imageLabel = document.createElement('label');
            imageLabel.innerText = 'Select Image File';
            imageLabel.height = '25px';
            imageInputDiv.appendChild(imageLabel);
            imageContainer.appendChild(imageInputDiv);

            const imageInput = document.createElement('input');
            imageInput.type = 'file';
            imageInput.name = 'myImageInput';
            imageInput.id = 'imageUrl';
            imageInput.style.opacity = 9;
            imageInput.style.position = 'inherit';
            imageInput.style.height = '25px';
            imageInput.style.width = '200px';
            imageInput.setAttribute('accept', 'image/*');
            imageInputDiv.appendChild(imageInput);
            imageContainer.appendChild(imageInputDiv);

            const imageButtonDiv = document.createElement('div');
            const imageButton = document.createElement('button');
            imageButton.innerText = 'Add';
            imageButton.className = 'toastui-editor-ok-button';

            //audio
            const container = document.createElement('form');
            container.name = 'myForm';
            container.method = 'POST';

            const inputDiv = document.createElement('div');
            const my_span = document.createElement('label');
            my_span.innerText = 'Select Audio File';
            my_span.height = '25px';
            inputDiv.appendChild(my_span);
            container.appendChild(inputDiv);

            const my_input = document.createElement('input');
            my_input.type = 'file';
            my_input.name = 'myInput';
            my_input.id = 'audioUrl';
            my_input.style.opacity = 9;
            my_input.style.position = 'inherit';
            my_input.style.height = '25px';
            my_input.style.width = '200px';
            my_input.setAttribute('accept', 'audio/*');

            inputDiv.appendChild(my_input);
            container.appendChild(inputDiv);

            //Playback Limit
            if(path == "question"){

                const autoplaylabel = document.createElement('label');
                autoplaylabel.innerText = 'Autoplay';
                autoplaylabel.height = '25px';
                inputDiv.appendChild(autoplaylabel);
                container.appendChild(inputDiv);


                const autoplaycheckbox = document.createElement('input');
                autoplaycheckbox.type = 'checkbox';
                autoplaycheckbox.name = 'autoplaychkbox';
                autoplaycheckbox.id = 'autoplaychkbox';
                autoplaycheckbox.style.opacity = 9;
                autoplaycheckbox.style.position = 'inherit';

                inputDiv.appendChild(autoplaycheckbox);
                container.appendChild(inputDiv);

                const my_span1 = document.createElement('label');
                my_span1.innerText = 'Select Playback Limit';
                my_span1.height = '25px';
                inputDiv.appendChild(my_span1);
                container.appendChild(inputDiv);

                const my_select1 = document.createElement('select');
                var opt1 = document.createElement('option');
                // my_select1.type = 'select';
                my_select1.name = 'playbacklimit';
                my_select1.id = 'playbacklimit';

                const min = 1; 
                const max = 9;
                opt1.value='No Limit';
                opt1.innerHTML = "No Limit";
                my_select1.appendChild(opt1);

                for (var i = min; i<=max; i++){
                    var opt = document.createElement('option');
                    opt.value = i;
                    opt.innerHTML = i;
                    my_select1.appendChild(opt);
                }

                my_select1.style.opacity = 9;
                my_select1.style.position = 'inherit';
                my_select1.style.height = '25px';
                my_select1.style.width = '200px';
                my_select1.style.marginBottom = '20px';

                inputDiv.appendChild(my_select1);
                container.appendChild(inputDiv);
            }

            const buttonDiv = document.createElement('div');
            const button = document.createElement('button');
            button.innerText = 'Add';
            button.className = 'toastui-editor-ok-button';


            //video UI
            const videoContainer = document.createElement('form');
            videoContainer.name = 'myVideoForm';
            videoContainer.method = 'POST';

            const videoInputDiv = document.createElement('div');
            const videoLabel = document.createElement('label');
            videoLabel.innerText = 'Select Video File';
            videoLabel.height = '25px';
            videoInputDiv.appendChild(videoLabel);
            videoContainer.appendChild(videoInputDiv);

            const videoInput = document.createElement('input');
            videoInput.type = 'file';
            videoInput.name = 'myVideoInput';
            videoInput.id = 'videoUrl';
            videoInput.style.opacity = 9;
            videoInput.style.position = 'inherit';
            videoInput.style.height = '45px';
            videoInput.style.width = '200px';
            videoInput.setAttribute('accept', 'video/*');
            videoInputDiv.appendChild(videoInput);
            videoContainer.appendChild(videoInputDiv);

            const videoButtonDiv = document.createElement('div');
            const videoButton = document.createElement('button');
            videoButton.innerText = 'Add';
            videoButton.className = 'toastui-editor-ok-button';

            //adding image UI to toolbar index order matters
            imageButton.setAttribute('type', 'button');
            imageButton.addEventListener('click', async () => {
                document.querySelector('.toastui-editor-popup').style.display = 'none';
                const fileURL = await addImage();
                const {data} = fileURL;
                const getMarkdown = editor.getMarkdown();
                //"![image](https://testlify-eu.s3.amazonaws.com/question/image/624d34794702b89866cc5766/1683624196844.png)"
                const formatedMd = `![image](${data})`;
                editor.setMarkdown(getMarkdown+formatedMd);
            });
            imageButtonDiv.appendChild(imageButton);
            imageContainer.appendChild(imageButtonDiv);

            editor.insertToolbarItem({groupIndex: 9, itemIndex: 0}, {
                name: 'imageUrl',
                tooltip: 'Insert Image',
                popup: {
                    body: imageContainer
                },
                text: 'Image',
                style: {width: '100px'}
            });


            button.setAttribute('type', 'button');
            button.addEventListener('click', async () => {
                document.querySelector('.toastui-editor-popup').style.display = 'none';
                const fileURL = await addAudio();
                if(fileURL != undefined){
                    const playbacklimit = await addAudioPlaybackLimit();
                    const autoplay = await addAudioautoplay();
                    const {data} = fileURL;
                    const getMarkdown = editor.getMarkdown();
                    // editor.setMarkdown(getMarkdown + " \n ```type:audio \n " + data + ";\nplaybacklimit:" + playbacklimit + ";\nautoplay:" + autoplay + " \n```");
                    editor.setMarkdown(getMarkdown + " \n ```type:audio \n " + data + " \n```" + " \n ```type:limit \n " + playbacklimit + " \n```" + " \n ```type:autoplay \n " + autoplay + " \n```");     
                }
            });
            buttonDiv.appendChild(button);
            container.appendChild(buttonDiv);

            editor.insertToolbarItem({groupIndex: 9, itemIndex: 1}, {
                name: 'audioUrl',
                tooltip: 'Insert Audio',
                className:'audioButton',
                popup: {
                    body: container
                },
                text: 'Audio',
                style: {width: '100px'}
            });

            videoButton.setAttribute('type', 'button');
            videoButton.addEventListener('click', async () => {
                document.querySelector('.toastui-editor-popup').style.display = 'none';
                const fileURL = await addVideo();
                const {data} = fileURL;
                const getMarkdown = editor.getMarkdown();
                editor.setMarkdown(getMarkdown + " \n ```type:video \n " + data + " \n```");
            });
            videoButtonDiv.appendChild(videoButton);
            videoContainer.appendChild(videoButtonDiv);

            editor.insertToolbarItem({groupIndex: 9, itemIndex: 2}, {
                name: 'videoUrl',
                tooltip: 'Insert Video',
                popup: {
                    body: videoContainer
                },
                text: 'Video',
                style: {width: '100px'}
            });



        } else {
            setToggle(!toggle)
        }
    }, [toggle])

    return (
        <div style={{marginTop: '80px'}}>
            <FormGroup>
                <Label required={isRequired}>{label}</Label>
                <InputGroup>
                    <div style={{width: '100%'}} id={'editor-' + selector}/>
                </InputGroup>
                <FormMessage/>
            </FormGroup>
        </div>
    )
}

export default MarkDownEditor