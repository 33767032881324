import {ApiClient} from 'adminjs'
import React from "react";
import {withTheme} from 'styled-components'
import Select from 'react-select'
import {FormGroup, Label, selectStyles} from '@adminjs/design-system'
const api = new ApiClient()

const MultiSelect = (props) => {
    const {theme, onChange, property} = props
    const [options, setOptions] = React.useState([])
    const [existingValue, setExistingvalue] = React.useState([])
    const [filteredOptions, setFilteredOptions] = React.useState([])
    const [defaultValues, setDefaultValues] = React.useState([])
    const [searchTerm, setSearchTerm] = React.useState('');
    const value = props.record.id
    var actionFunc, label, placeholder = '';

    if(property.path == 'industryType'){
        actionFunc = 'getIndustryType';
        label = 'Industry Type';
        placeholder = 'Select';
    }
    else if(property.path == 'jobRoles'){
        actionFunc = 'getJobRole';
        label = 'Job Roles';
        placeholder = 'Please enter min 2 keywords';
    }

    React.useEffect(()=>{
        const fetchData = async() => {
            const response = await api.resourceAction(
                {
                    resourceId: 'TestLibrary',
                    actionName: actionFunc,
                    data: {new: true}
                }
            )
            setOptions(response.data)
        }
        const fetchExistingData = async() => {
            const response = await api.resourceAction(
                {
                    resourceId: 'TestLibrary',
                    actionName: actionFunc,
                    data: {view: true, id: value}
                }
            )
            setExistingvalue(response.data)
        }
        fetchData()
        fetchExistingData()
    },[])

    React.useEffect(()=>{
        if(property.path == 'industryType'){
            setFilteredOptions(options.map((e)=>({value:e._id, label:e.name})))
        }
    },[options])

    React.useEffect(()=>{
        if(property.path == 'industryType'){
            setDefaultValues(existingValue.map((e)=>({value:e.filtered._id, label:e.filtered.name})))
        }
        else if(property.path == 'jobRoles'){
            setDefaultValues(existingValue.map((e)=>({value:e.filtered.code, label:e.filtered.name})))
        }
    },[existingValue])

    const handleInputChange = (value) => {
        property.path == 'jobRoles' && setFilteredOptions([]);
        if(property.path == 'jobRoles' && value.length > 1){
            const filtered = options.filter( (i) => i.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()) );
            setFilteredOptions(filtered.map((e)=>({value:e.code, label:e.name})));
        }
    };

    return (
        <>
            <FormGroup>
                <Label>{label}</Label>
                <Select options={filteredOptions} isMulti
                value={defaultValues}
                closeMenuOnSelect={false}
                placeholder={ placeholder }
                onChange={(value)=>{
                    onChange(property.path, (value || []).map((e)=>e.value))
                    setDefaultValues(value || [])
                }}
                onInputChange={handleInputChange}
                />
            </FormGroup>
        </>
    )
}
export default withTheme(MultiSelect)