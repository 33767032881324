import React from 'react';
import { FormGroup, Label, InputGroup, FormMessage, Section, Button } from '@adminjs/design-system';
import { Trash2 } from 'react-feather';

const MultipleMarkdownEditor = (props) => {
    const { onChange, record, property } = props;
    const [values, setValues] = React.useState([]);
    const [toggle, setToggle] = React.useState(false);

    const isRequired = property.isRequired || false;
    const label = property.label || '';
    const path = property.path || '';
    const selector = path.replaceAll('.', '-');

    // Initialize state from record.params
    React.useEffect(() => {
        const initialValues = Object.keys(record.params)
            .filter((key) => key.startsWith(`${path}.`))
            .sort((a, b) => Number(a.split(".")[1]) - Number(b.split(".")[1]))
            .map((key) => record.params[key]);

        setValues(initialValues);
    }, [record.params, path]);

    // Function to initialize editors when values change
    React.useEffect(() => {
        if (typeof toastui !== 'undefined') {

            values.forEach((value, index) => {
                const editorContainer = document.querySelector(`#editor-${path}-${index}`);

                if (editorContainer && !editorContainer.dataset.initialized) {
                    editorContainer.dataset.initialized = "true"; // Prevent re-initialization

                    const editor = new toastui.Editor({
                        el: editorContainer,
                        height: '300px',
                        usageStatistics: false,
                        initialValue: value,
                        initialEditType: 'wysiwyg',
                        hideModeSwitch: true,
                        autofocus: false,
                        useDefaultHTMLSanitizer: true,
                        toolbarItems: [
                            ['heading', 'bold', 'italic', 'strike'],
                            ['hr', 'quote'],
                            ['ul', 'ol', 'task', 'indent', 'outdent'],
                            ['table', 'link'],
                            ['code', 'codeblock'],
                        ],
                        events: {
                            blur: function () {
                                const md = editor.getMarkdown();
                                updateValue(index, md);
                            },
                        },
                        previewStyle: 'vertical',
                    });
                }
            });

        } else {
            setToggle(!toggle);
        }
    }, [values, toggle]);

    // Function to update values when the editor content changes
    const updateValue = (index, newValue) => {
        const updatedValues = [...values];
        updatedValues[index] = newValue;
        setValues(updatedValues);

        const updatedParams = {};
        updatedValues.forEach((val, i) => {
            updatedParams[`${i}`] = val;
        });

        onChange(path, updatedParams);
    };

    // Handle item removal
    const handleRemove = (index) => {
        console.log("Removing index:", index);

        const newValues = values.filter((_, i) => i !== index);

        console.log("Updated Values:", newValues);

        setValues(newValues);

        const updatedParams = {};
        newValues.forEach((val, i) => {
            updatedParams[`${i}`] = val;
        });

        onChange(path, updatedParams);
    };

    // Handle adding new item
    const handleAdd = () => {
        const newValues = [...values, ''];
        setValues(newValues);

        const updatedParams = {};
        newValues.forEach((val, i) => {
            updatedParams[`${i}`] = val;
        });

        onChange(path, updatedParams);
    };

    return (
        <div style={{ marginTop: '80px' }}>
            <Label style={{textTransform:'capitalize'}}>{label}</Label>
            <Section style={{ marginBottom: '40px' }}>
                {values.map((value, index) => (
                    <div key={`${index}`}>
                        <Label id={label+index}>
                            {index + 1}
                            <Trash2
                                style={{ float: 'right', cursor: 'pointer', color:'red', width : '18px' }}
                                onClick={() => handleRemove(index)}
                            />
                        </Label>
                        <FormGroup>
                            <InputGroup>
                                <div style={{ width: '100%' }} id={`editor-${path}-${index}`} />
                            </InputGroup>
                            <FormMessage />
                        </FormGroup>
                    </div>
                ))}
                <Button
                    type="button"
                    style={{ textTransform: 'capitalize', border: '1px solid', marginTop: '20px' }}
                    onClick={handleAdd}
                >
                    + Add More {label}
                </Button>
            </Section>
        </div>
    );
};

export default MultipleMarkdownEditor;
