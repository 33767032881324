import React from 'react';
import { Box, Text } from '@adminjs/design-system';

const AdditionalBillingCost = (props) => {
  const { record, property } = props;
  const value = record.params?.additionalBillingCost;
  
  return (
    <Box>
      <Text>{value || '-'}</Text>
    </Box>
  );
};

export default AdditionalBillingCost;