import {ApiClient} from 'adminjs';
import React, {useEffect} from 'react';
import {withTheme} from 'styled-components';
import {FormGroup, Label, Button, Icon, Loader} from '@adminjs/design-system';
import mongoose from 'mongoose';


const api = new ApiClient();

const fileDownload = (response) => {
    const blob = new Blob([response.data.res], {type: 'text/csv'});
    const downloadUrl = URL.createObjectURL(blob);

    const downloadLink = document.createElement('a');
    downloadLink.href = downloadUrl;
    downloadLink.download = 'result.csv';
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(downloadUrl);
}

const candidateResult = (props) => {
    const {theme, records, action} = props;
    const [OId, setOId] = React.useState('');
    const [result, setResult] = React.useState('');

    useEffect(() => {
        return () => {

            setResult('')
        }
    }, [])

    const takeAction = async () => {
        let response = ''
        if (action.name === 'candidateResult') {
            response = await api.resourceAction({
                resourceId: 'Question',
                actionName: 'candidateResult',
                data: {
                    assessmentId: OId,
                },
            })
            fileDownload(response)
            setResult('Downloaded!')
            return response
        }
        if (action.name === 'csvDownload') {
            response = await api.resourceAction({
                resourceId: 'Question',
                actionName: 'csvDownload',
                data: {
                    testLibraryId: OId,
                },
            })
            fileDownload(response)
            setResult('Downloaded!')
            return response
        }
        if (action.name === "testLibraryResult") {
            response = await api.resourceAction({
                resourceId: 'TestLibrary',
                actionName: 'testLibraryResult',
                data: {
                    testLibraryId: OId,
                },
            })
            fileDownload(response)
            setResult('Downloaded!')
            return response
        }
    }

    const submit = async () => {
        if (!OId) {
            setResult('Empty String please pass a value')
        } else if (!mongoose.Types.ObjectId.isValid(OId)) {
            setResult('Invalid assessmentId. Please provide a valid ObjectId.');
        } else {
            await takeAction(action.name)
        }
    };

    return (
        <>
            <FormGroup>
                <Label>{
                    
                        {
                          'csvDownload': 'TestLibraryId to download a csv',
                          'testLibraryResult': 'TestLibraryId to download a result',
                          default: 'Candidate Result'
                        }[action.name] || 'Candidate Result'
                      
                    }</Label>
                <input
                    type="text"
                    value={OId}
                    onChange={(e) => setOId(e.target.value)}
                    style={{width: '100%'}}
                />
            </FormGroup>
            <div style={{marginTop: '20px'}}>
                <Button style={{float: 'right'}} rounded={true} onClick={submit}>
                    <Icon icon="Download"/>
                    {'Download'}
                </Button>
                {result ? (
                    <textarea
                        disabled
                        style={{
                            marginTop: '20px',
                            width: '100%',
                            height: '100px',
                            color: '#000',
                        }}
                        value={result}
                    />
                ) : (
                    <div/>
                )}
            </div>

        </>
    );
};

export default withTheme(candidateResult)





