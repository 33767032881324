import React from 'react'
import {flat} from 'adminjs'
import {
    FormGroup,
    Icon,
    CheckBox,
    Button,
    Label,
    InputGroup,
    FormMessage
} from '@adminjs/design-system'
import SelectBox from '@toast-ui/select-box';
import Select from 'react-select';
import { LANGUAGE_ENUMS } from '../../utils/language';

const TestlifyOptionsMd = (props) => {
    const {onChange, record, property} = props

    const [optionLength, setOptionLength] = React.useState(0)

    const getOptions = () => {
        return flat.get(record.params, 'options') || []
    }

    const options = getOptions();

    // load options if option length is changed
    if (options.length !== optionLength) {
        setOptionLength(options.length)
    }

    const triggerAddOption = () => {
        onChange('options.' + options.length + '.description', 'Option ' + (options.length + 1))
    }

    const triggerRemoveOption = (index) => {
        const updatedParams = flat.removePath(record.params, 'options.' + index)
        onChange({
            ...record,
            params: updatedParams
        })
    }

    const updateIsCorrectValue = (index, value) => {
        onChange('options.' + index + '.isCorrect', value)
    }

    const updateLanguageValue = (index, value) => {
        onChange('options.' + index + '.language', value)
    }

    const updateScoreValue = (index, value) => {
        value = parseInt(value)
        onChange('options.' + index + '.score', value)
    }
    const scoreLevel = record?.params?.scoreLevel;

    React.useEffect(() => {
        let editors = []
        options.forEach((value, index, array) => {
            editors[index] = new toastui.Editor({
                el: document.querySelector('#editor-' + index),
                usageStatistics: false,
                initialEditType: 'wysiwyg',
                initialValue: value.description || '',
                hideModeSwitch: true,
                events: {
                    change: function () {
                        onChange('options.' + index + '.description', editors[index].getMarkdown())
                    }
                },
                previewStyle: 'vertical'
            });
        });
    }, [optionLength])

    const option = [
        ...Object.values(LANGUAGE_ENUMS).map((value, index) => {
            return {
                label: value,
                value: value
            }
        })
    ];

    return (

        <div>
            <Label required>Options</Label>

            {options.map(function (object, index) {
                return <table key={index}>
                    <tbody>
                    <tr>
                        <td>
                            <div style={{marginBottom: '20px', zIndex: 0, position: 'relative'}} id={'editor-' + index}/>
                        </td>
                        <td style={{padding: '30px'}} rowSpan={2}>
                            <div style={{marginBottom: '30px'}}>
                                <CheckBox onChange={() => updateIsCorrectValue(index, !object.isCorrect)}
                                          checked={object.isCorrect}/>
                                <Label inline size='lg' ml="default">Is Correct</Label>
                            </div>
                            <div>
                                <Button variant={'danger'} rounded={true} size="icon" mr="default"
                                        onClick={() => triggerRemoveOption(index)}
                                        type="button">
                                    <Icon icon="Delete"/>
                                </Button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{marginBottom: '30px'}}>
                                <Select
                                    options={option}
                                    placeholder="Select Language"d
                                    onChange={(e) => updateLanguageValue(index, e.value)}
                                    styles={{ zIndex: 2 }}
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ marginBottom: '30px' }}>
                                <Label>Option Level Scoring</Label>
                                <InputGroup>
                                    <input class="sc-iqAbSa jkMUDW adminjs_Input" type="number" value={object.score} width="80" height="80" placeholder='score' onChange={(e) => updateScoreValue(index, e.target.value)} />
                                </InputGroup>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            })}

            <Button style={{marginBottom: 30}} rounded={true} onClick={triggerAddOption} type="button">
                <Icon icon="Add"/>
                Add Option
            </Button>
        </div>


    )
}

export default TestlifyOptionsMd