import {ApiClient} from 'adminjs'
import React from "react";
import {withTheme} from 'styled-components'
import {FormGroup, Label} from '@adminjs/design-system'
const api = new ApiClient()
const JobRoleView = (props) => {
    const [values, setValues] = React.useState([])
    const value = props.record.id

    React.useEffect(()=>{
        const fetchData = async() => {
            const response = await api.resourceAction(
                {
                    resourceId: 'TestLibrary',
                    actionName: 'getJobRole',
                    data: {view: true, id:value}
                }
            )
            setValues(response.data)
        }
        fetchData()
    },[])
    return (
        <div>
            <FormGroup>
            <Label variant="light">Job Role</Label>
            {values.map((val)=>{
                return(
                    <>
                        <Label>{val.filtered.name}</Label><br/>
                    </>
                )
            })}
            </FormGroup>
        </div>
    )
}
export default withTheme(JobRoleView)
