import {ApiClient} from 'adminjs'
import React from "react";
import {withTheme} from 'styled-components'
import Select from 'react-select'
import {FormGroup, Label, selectStyles, Button, Icon, TextArea, Loader} from '@adminjs/design-system'
const api = new ApiClient()

const BulkAction = (props) => {
    const {theme, records, action} = props
    const [options, setOptions] = React.useState([]);
    const [filteredOptions, setFilteredOptions] = React.useState([]);
    const [selectedTestlibrary, setSelectedTestlibrary] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [result, setResult] = React.useState('')

    React.useEffect(()=>{
        const fetchTestLibrary = async() => {
            const response = await api.resourceAction(
                {
                    resourceId: 'Question',
                    actionName: 'customAction',
                    data: {testLibrary: true}
                }
            )
            setOptions(response.data)
        }
        fetchTestLibrary()
    },[])

    React.useEffect(()=>{
        setFilteredOptions(options.map((e)=>({value:e._id,label:e.title})))
    },[options])

    const takeAction = async (action) => {
        setLoading(true)
        const questionIdList = records.map(val => val.id)
        let moveQuestion = action === 'moveAll' ? true : false;
        let copyQuestion = action === 'copyAll' ? true : false;

        const response = await api.resourceAction(
            {
                resourceId: 'Question',
                actionName: 'customAction',
                data: {
                    moveQuestion,
                    copyQuestion,
                    testLibraryId: selectedTestlibrary,
                    questionIdList
                }
            }
        )
        setResult(response.data.message)
        setLoading(false)
    }
    const submit = () => {
        if(!selectedTestlibrary) return
        takeAction(action.name)
    }
    return (
        <>
            <FormGroup>
                <Label>Test Library</Label>
            <Select options={filteredOptions} 
            onChange={(e)=>{
                setSelectedTestlibrary(e.value)
            }}/>
            </FormGroup>
            <div style={{marginTop: '20px'}}>
                <Button style={{float: 'right'}} rounded={true} onClick={submit}>
                    <Icon icon="WorkspaceImport"/>
                    {action.name === 'moveAll' ? 'Move': 'Copy'}
                </Button>
                {loading
                    ? <Loader/>
                    : result ? <TextArea
                        disabled
                        style={{
                            marginTop: '20px',
                            width: '100%',
                            height: '100px',
                            color: '#000'
                        }}
                        value={result}
                    /> : <div/>
                }
            </div> 
        </>
    )
}

export default BulkAction
