import { ApiClient } from 'adminjs';
import React, { useEffect } from 'react';
import { withTheme } from 'styled-components';

const api = new ApiClient();

const BulkAction = (props) => {
    const { records, resource } = props;
    console.log("Resouce",props.resource)
    useEffect(() => {
        if (resource?.href) {
            window.location.replace(resource.href);  
        } else {
            console.log('No redirect URL found in action props');
        }
    }, [resource]);

    return null;
};

export default withTheme(BulkAction);
