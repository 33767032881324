import { FormGroup, Label, Input } from "@adminjs/design-system";

const ApiKeyIntegrationUrlField = (props) => {
    const { property, onChange, record } = props;
    const isApiKeyIntegration = record.params.isApiKeyIntegration;

    if (isApiKeyIntegration) {
        return (
            <FormGroup>
                <Label>{property.label}</Label>
                <Input
                    type="text"
                    value={record.params.apiKeyIntegrationUrl || ""}
                    onChange={(event) => {
                        onChange(property.name, event.target.value);
                    }}
                />
            </FormGroup>
        );
    }

    return null;
};

export default ApiKeyIntegrationUrlField;
