import React from 'react';
import {Label, Box, DropZone, Button} from '@adminjs/design-system';
import {ApiClient} from 'adminjs';
import { Trash2 } from 'react-feather';

const api = new ApiClient();


const Upload = function (props) {
    const {property, onChange, record} = props
    const [uploadedFile, setUploadedFile] = React.useState(record?.params[property.name] || null);
    const [loadingMsg, setLoadingMsg] = React.useState('');
    
    const allowedTypes = (property.path == 'technicalManual') ? ['application/pdf'] : ['image/jpeg', 'image/png'];
    const validationMsg = (property.path == 'technicalManual') ? 'Invalid file type. Please upload an pdf file.' : 'Invalid file type. Please upload an image file (JPEG, PNG, GIF).';

    const handleImage = async (files) => {
        try {
            let fileData = ""
            let type = ""
            let mime = ""
            
            if (files[0].type === "image/svg+xml") {
                const file = files[0];
                try {
                    fileData = await readFileAsText(file);
                    type = "image";
                    mime = "svg";
                } catch (error) {
                    console.error("Error reading SVG file:", error);
                }
            } else {
                fileData = await convertBase64(files[0]);
                type = fileData.split(';')[0].split(':')[1].split('/')[0];
                mime = fileData.split(';')[0].split(':')[1].split('/')[1];
            }
            let response = ''
            
            if (type === 'image') {
                if(property.name==='image'){
                    if (property.resourceId === "CodingTemplate"){

                        response = await api.resourceAction(
                            {
                                resourceId: 'CodingTemplate',
                                actionName: 'imageUpload',
                                data: {new: fileData, mime: mime, slug: record.params.slug}
                            }
                        );
                    } else {
                        response = await api.resourceAction(
                            {
                                resourceId: 'ApplicationTrackingSystem',
                                actionName: 'imageUploadATS',
                                data: {new: fileData, mime: mime, slug: record.params.slug}
                            }
                        );
                    }
                }
                if (property.name === 'squareImage') {
                    response = await api.resourceAction(
                        {
                            resourceId: 'ApplicationTrackingSystem',
                            actionName: 'imageSquareUploadATS',
                            data: {new: fileData, mime: mime, slug: record.params.slug}
                        }
                    );
                }
                onChange(property.name, response.data)
                return response
            } 
            else if(type == 'application' && files[0].type == 'application/pdf'){
                setLoadingMsg('Please wait Technical Manual is uploading....');
                response = await api.resourceAction(
                    {
                        resourceId: 'TestLibrary',
                        actionName: 'fileUpload',
                        data: {new: fileData, mime: mime, _id: record.params._id}
                    }
                );

                if(response){
                    setLoadingMsg('Technical Manual is uploaded successfully.');
                    setTimeout(() => {
                        setLoadingMsg(""); // Clear message after 5 seconds
                    }, 2000);
                }

                setUploadedFile(response.data);
                onChange(property.name, response.data)
                return response;
            }
            else {
                alert('Wrong Input Type');
            }
        } catch (err) {
            console.log(err)
        }
    };


    const handleRemoveFile = async () => {
        let response = ''
        if(property.path == 'technicalManual'){
            if(window.confirm("Are you sure you want to remove Technical Manual?") && uploadedFile){  
                try {
                    response = await api.resourceAction({
                        resourceId: 'TestLibrary',
                        actionName: 'UpdateTechnicalManual',
                        data: { _id: record.params._id }
                    });
                    setUploadedFile(null);
                    onChange(property.name, null);
                    return response;
                } catch (err) {
                    console.error("Error deleting file:", err);
                }
            }
        }
        else {
            alert('Something went wrong in File Removed.');
        }
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }
    const readFileAsText = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                resolve(e.target.result);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsText(file);
        });
    };
    const validateImage = (file) => {
        if (!allowedTypes.includes(file.type)) {
            return validationMsg;
        }
        return true;
    };

    return (
        <Box marginBottom="xxl">
            <Label style={{textTransform:'capitalize'}}>{property.label} 
                <span style={{ float: 'right', color:'red' }}>{loadingMsg}</span>
            </Label>

            {uploadedFile ? (
                <Box>
                    <a href={uploadedFile} target="_blank" rel="noopener noreferrer">{uploadedFile}</a> 
                    <Trash2
                        style={{ float: 'right', cursor: 'pointer', color:'red', width : '18px' }}
                        onClick={handleRemoveFile}
                    />
                </Box>
            ) : ('')}
            <br/>
            <DropZone onChange={handleImage}
                      accept={ (property.path == 'technicalManual') ? "application/pdf" : "image/*" }
                      inputContent={ (property.path == 'technicalManual') ? "" : (files, extra) => (extra.reject ? 'Image files only' : 'Drag Files')}
                      validate={validateImage}/>
        </Box>
    );
};

export default Upload;
