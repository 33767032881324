import {ApiClient} from 'adminjs'
import React from "react";
import {withTheme} from 'styled-components'
import Select from 'react-select'
import {FormGroup, Label, selectStyles} from '@adminjs/design-system'
const api = new ApiClient()
const JobRoleEdit = (props) => {
    const {theme, onChange, record, property} = props
    const [options, setOptions] = React.useState([])
    const [existingValue, setExistingvalue] = React.useState([])
    const [filteredOptions, setFilteredOptions] = React.useState([])
    const [defaultValues, setDefaultValues] = React.useState([])
    const value = props.record.id

    React.useEffect(()=>{
        const fetchData = async() => {
            const response = await api.resourceAction(
                {
                    resourceId: 'TestLibrary',
                    actionName: 'getJobRole',
                    data: {new: true}
                }
            )
            setOptions(response.data)
        }
        const fetchExistingData = async() => {
            const response = await api.resourceAction(
                {
                    resourceId: 'TestLibrary',
                    actionName: 'getJobRole',
                    data: {view: true, id:value}
                }
            )
            setExistingvalue(response.data)
        }
        fetchData()
        fetchExistingData()
    },[])

    React.useEffect(()=>{
        setFilteredOptions(options.map((e)=>({value:e.code,label:e.name})))
    },[options])

    React.useEffect(()=>{
        setDefaultValues(existingValue.map((e)=>({value:e.filtered.code,label:e.filtered.name})))
    },[existingValue])
    
    return (
        <div style={{marginBottom:"40px"}}>
            <FormGroup>
            <Label>Job Role</Label>
            <Select options={filteredOptions} isMulti 
            value={defaultValues}
            onChange={(value)=>{
                onChange(property.path, (value || []).map((e)=>e.value))
                setDefaultValues(value || [])
            }}/>
            </FormGroup>
        </div>
    )
}
export default withTheme(JobRoleEdit)