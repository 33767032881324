import {ApiClient} from 'adminjs';
import React, {useEffect} from 'react';
import {FormGroup, Label, Button, Icon, Loader} from '@adminjs/design-system';
const api = new ApiClient();

const fileDownload = (response) => {
    const blob = new Blob([response.data.csvContent], {type: 'text/csv'});
    const downloadUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = downloadUrl;
    downloadLink.download = 'result.csv';
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(downloadUrl);
}

const DownloadCsvButton = (props) => {
    const {theme, records, action} = props;
    const [result, setResult] = React.useState('');
    useEffect(() => {
        return () => {
            setResult('')
        }
    }, []);

    const handleClick = async () => {
        let response = '';
        if (action.name === 'csvDownload') {
            response = await api.resourceAction({
                resourceId: 'Language',
                actionName: 'csvDownload',
            })
            fileDownload(response)
            setResult('Downloaded!')
            return response
        }
    };

    return (
        <>
            <div style={{marginTop: '20px'}}>
                <Button style={{border:'1px solid'}} rounded={true} onClick={handleClick}>
                    <Icon icon="Download"/>
                    {'Download'}
                </Button>
                {result ? (
                    <textarea
                        disabled
                        style={{
                            marginTop: '20px',
                            width: '100%',
                            height: '100px',
                            color: '#000',
                        }}
                        value={result}
                    />
                ) : (
                    <div/>
                )}
            </div>

        </>
    );
};

export default DownloadCsvButton;





