import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  DrawerContent,
  Box,
  DrawerFooter,
  Button,
  Icon,
  Modal,
  MessageBox
} from '@adminjs/design-system';
import { useNotice, BasePropertyComponent, ActionHeader, useRecord, useTranslation, LayoutElementRenderer } from 'adminjs';

const Edit = (props) => {
  const { record: initialRecord, resource, action } = props;
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [pendingSubmit, setPendingSubmit] = useState(false);
  const addNotice = useNotice();
  
  const {
    record,
    handleChange,
    submit: handleSubmit,
    loading,
    setRecord,
  } = useRecord(initialRecord, resource.id);
  const { translateButton } = useTranslation();
  const history = useNavigate();

  useEffect(() => {
    if (initialRecord) {
      setRecord(initialRecord);
    }
  }, [initialRecord, setRecord]);

  const handleStatusChange = (event) => {
    event.preventDefault();
    
    const originalStatus = initialRecord?.params?.status;
    const newStatus = record?.params?.status;
    
    if (originalStatus === 'PUBLISHED' && newStatus !== 'PUBLISHED') {
      setShowStatusModal(true);
      setPendingSubmit(true);
      return;
    }
    
    submit();
  };

  const submit = () => {
    handleSubmit().then((response) => {
      if (response.data.redirectUrl) {
        const title = record?.params?.title;
        const testLibraryId = record?.params?._id;
        
        addNotice({
          message: `Successfully updated the record with test name: "${title}" & id: ${testLibraryId}`,
          type: 'success',
        });

        history(response.data.redirectUrl, {
          state: { previousPage: window.location.href },
        });
      }
    });
    setPendingSubmit(false);
    setShowStatusModal(false);
  };

  const cancelStatusChange = () => {
    setPendingSubmit(false);
    setShowStatusModal(false);
  };

  const goBack = () => {
    const previousUrlHasShow = window.history.state?.state.previousPage.includes('show');
    previousUrlHasShow ? history.go(-2) : history.go(-1);
  };

  return (
    <>
      <Box as="form" onSubmit={handleStatusChange} flex flexGrow={1} flexDirection="column">
        <DrawerContent>
          {action?.showInDrawer && <ActionHeader {...props} />}
          {action.layout ? action.layout.map((layoutElement, i) => (
            <LayoutElementRenderer
              key={i}
              layoutElement={layoutElement}
              {...props}
              where="edit"
              onChange={handleChange}
              record={record}
            />
          )) : resource.editProperties.map(property => (
            <BasePropertyComponent
              key={property.propertyPath}
              where="edit"
              onChange={handleChange}
              property={property}
              resource={resource}
              record={record}
            />
          ))}
        </DrawerContent>
        <DrawerFooter>
          <Button variant="primary" size="lg" type="submit" data-testid="button-save" disabled={loading}>
            {loading && <Icon icon="Fade" spin />}
            {translateButton('save', resource.id)}
          </Button>
          <Button onClick={goBack} style={{ marginLeft: '20px' }} variant="default" size="lg" type="button" data-testid="button-cancel">
            {translateButton('cancel', resource.id)}
          </Button>
        </DrawerFooter>
      </Box>

      {showStatusModal && (
        <Modal onOverlayClick={() => setShowStatusModal(false)} onClose={() => setShowStatusModal(false)}>
          <MessageBox
            variant="warning"
            message="Are you sure you want to change the status to Draft or Archive?"
            marginBottom="xxl"
          />
          <Box flex justifyContent="flex-end">
            <Button variant="primary" size="lg" onClick={submit} style={{ marginRight: '10px' }}>
              Confirm
            </Button>
            <Button variant="default" size="lg" onClick={cancelStatusChange}>
              Cancel
            </Button>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default Edit;
