import React from 'react'
import {ApiClient} from 'adminjs'
import {FormGroup, Label, Input} from '@adminjs/design-system'

const api = new ApiClient();
const MarkDownViewer = (props) => {

    const { onChange, record, property } = props;
    const label = property.label || '';
    const [userName, setUserName] = React.useState('')
    const [lastActivity, setLastActivity] = React.useState('')
    const [ownerEmail, setOwnerEmail] = React.useState('')
    const [workspaceUrl, setWorkspaceUrl] = React.useState('')
    const [workspaceName, setWorkspaceName] = React.useState('')
    const fetchData = async() => {
        const response = await api.resourceAction(
            {
                resourceId: 'Organization',
                actionName: 'customAction',
                data: { id: record.id }
            }
        )
        setUserName(`${(response.data.firstName ?? 'No data')} ${(response.data.lastName ?? '')}`)
        setLastActivity(new Date(response.data.lastSeenAt).toLocaleString())
        setOwnerEmail(response.data.email ?? 'No email')
    }
    const fetchCandidateWorkspaceDetails = async() => {
        const response = await api.resourceAction(
            {
                resourceId: 'Organization',
                actionName: 'customActionFetch',
                data: { orgId: record.params.orgId}
            }
        )
        setWorkspaceUrl(response.data.workspaceUrl ?? 'No Workspace Url')
        setWorkspaceName(response.data.name ?? 'No Workspace Name')
    }
    React.useEffect(()=>{
        property.resourceId === "CandidateWorkspaceProfile" ? fetchCandidateWorkspaceDetails() : fetchData()
    },[])

    return ( 
        <>
            <FormGroup>
                {['show','filter'].includes(props.where) && <Label variant="light">{label}</Label>}
                {!['filter'].includes(props.where) &&
                    <div >{
                        (property.label === 'Owner')
                            ? userName
                            : (
                                (property.label === 'Owner Email')
                                    ? ownerEmail
                                    : (
                                        (property.label === 'Workspace Url')
                                            ? workspaceUrl
                                            : (
                                                (property.label === 'Workspace Name')
                                                    ? workspaceName
                                                    : lastActivity
                                            )
                                    )
                            )
                    }</div>}
                {['filter'].includes(props.where) && <Input
                    onChange={(value)=>{
                        onChange(property.path, (value || ''))
                    }}
                    
                />}
            </FormGroup>
        </>
        
    )
}

export default MarkDownViewer