import { ApiClient } from 'adminjs'
import React, { useEffect, useState } from 'react'
import { withTheme } from 'styled-components'
import Select from 'react-select'
import { FormGroup, Label } from '@adminjs/design-system'
import { LANGUAGE_CODES } from "../utils/language";
import { QUESTION_STATUS, TESTLIBRARY_STATUS } from "../config/constants";
const api = new ApiClient();

const CustomSelectOption = (props) => {
    const { onChange, resource, record, property } = props

    const [options, setOptions] = React.useState([])
    const [filteredOptions, setFilteredOptions] = React.useState([])
    const [isDisabled, setisDisabled] = React.useState(false)

    const value = (record) ? record.params[property.path] : '';
    const selectedValue = options.find(o => o.value === value);
    let label = 'Translation Langauge';
    let actionFunc = ''; 
    let placeholder = 'Select';
    let STATUSVL, TLID;
    if(property.path == 'jobRoles'){
        actionFunc = 'getJobRole';
        label = 'Job Role';
        placeholder = 'Please enter min 2 keywords';
    }
    else if(property.path == 'industryType'){
        actionFunc = 'getIndustryType';
        label = 'Industry Type';
        placeholder = 'Select';
    }
    else if(property.path == 'status'){
        label = 'Status';
        placeholder = 'Select Status';

        //Only for TestLibrary & Question Resources
        STATUSVL = (resource.id == 'TestLibrary') ? TESTLIBRARY_STATUS : QUESTION_STATUS;
        TLID = (resource.id == 'TestLibrary') ? record.params._id : record.params.testLibraryId;
    }
    
    useEffect(() => {
        const fetchOptions = async () => {
            if(property.path == 'jobRoles' || property.path == 'industryType'){
                const response = await api.resourceAction(
                    {
                        resourceId: 'TestLibrary',
                        actionName: actionFunc,
                        data: {new: true}
                    }
                )
                setOptions(response.data)
            }
            else if(property.path == 'status'){
                const vlstatus = Object.keys(STATUSVL).map((i) => ({
                    value: i,
                    label: STATUSVL[i]
                  }))
                setOptions(vlstatus)

                const assessment = await api.resourceAction(
                    {
                        resourceId: 'TestLibrary',
                        actionName: 'getAssessment',
                        data : {testLibraryId : TLID}
                    }
                )
                if(assessment.data.record > 0){
                    setisDisabled(record?.params.status === 'PUBLISHED');
                }
            }  
            else{
                const languagescode = Object.keys(LANGUAGE_CODES).map((i) => ({
                    value: i,
                    label: LANGUAGE_CODES[i]
                  }))
                setOptions(languagescode)
            }  
        }
        fetchOptions()
    }, [])

    const handleInputChange = (value) => {
        property.path == 'jobRoles' ? setFilteredOptions([]) : '';
        if(property.path == 'jobRoles' && value.length > 1){
            const filtered = options.filter( (i) => i.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()) );
            setFilteredOptions(filtered.map((e)=>({value:e.code, label:e.name})));
        }
    };

    React.useEffect(()=>{
        if(property.path == 'industryType'){
            setFilteredOptions(options.map((e)=>({value:e._id, label:e.name})))
        }
    },[options])

    const handleSelectChange = (selected) => {
        onChange(property.path, selected?.value ?? '')
    }

    return (
        <FormGroup>
            <Label>{label}</Label>
            
            { ( property.path == 'jobRoles' || property.path == 'industryType' ) ? (
                <Select
                    placeholder={placeholder}
                    isClearable
                    options={filteredOptions}
                    onChange={handleSelectChange}
                    onInputChange={handleInputChange}
                />
            ) : (
                <Select
                    placeholder={placeholder}
                    isClearable
                    isDisabled={isDisabled}
                    options={options}
                    value={typeof selectedValue === 'undefined' ? '' : selectedValue}
                    onChange={handleSelectChange}
                />
            )}
            
        </FormGroup>
    )
}

export default withTheme(CustomSelectOption)
