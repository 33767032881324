import React from 'react'
import {
    DrawerContent,
    Box,
    DrawerFooter,
    Button,
} from '@adminjs/design-system'
import { ActionHeader, LayoutElementRenderer, ApiClient, useNotice, BasePropertyComponent } from 'adminjs'
const api = new ApiClient()

const updateAiGeneratedQ = (props) => {
    const { resource, action, record} = props
    const sendNotice = useNotice()
    const buttonFlag = record.params?.aiCorrection == true && record.params?.isAiAnswerReplaced == false 
    const updateQuestion = async () => {
        const response = await api.resourceAction({
            resourceId: 'Question',
            actionName: 'updateAiGeneratedAnswer',
            data: {question: record.params._id}
        })
        const stringifyResponse = JSON.stringify(response.data, null, 2)
        sendNotice({ message: stringifyResponse, type: response.data.modifiedCount > 0 ? 'success' : 'error' })
    }
    return(
    <>
        <Box flex flexGrow={1} flexDirection="column">
            <DrawerContent>
                {action?.showInDrawer ? <ActionHeader {...props} /> : null}
                {action.layout
                    ? action.layout.map((layoutElement, i) => (
                          <LayoutElementRenderer
                              // eslint-disable-next-line react/no-array-index-key
                              key={i}
                              layoutElement={layoutElement}
                              {...props}
                              where="show"
                          />
                      ))
                    : resource.showProperties.map((property) => (
                          <BasePropertyComponent
                              key={property.propertyPath}
                              where="show"
                              property={property}
                              resource={resource}
                              record={props.record}
                          />
                      ))}
            </DrawerContent>
            <DrawerFooter>{
                buttonFlag ? <Button
                onClick={updateQuestion}                        
                variant="default"
                size="lg"
                type="button">
                Update Ai Generated Answer
            </Button>: ''}
            </DrawerFooter>
        </Box>
    </>
    )
}

export { updateAiGeneratedQ as default, updateAiGeneratedQ }