import React, { useEffect, useState } from 'react';
import { ApiClient } from 'adminjs';

const api = new ApiClient();

const JobRoleDetailCounting = ({ record, property }) => {
  const [assessmentCount, setAssessmentCount] = useState(0);
  const [testLibraryCount, setTestLibraryCount] = useState(0);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const assessmentResponse = await api.resourceAction({
          resourceId: 'JobRole',
          actionName: 'assessmentAndTestLibraryCount',
          data: { jobRoleId: record.id }
        });
        setAssessmentCount(assessmentResponse.data.assessmentCount);
        setTestLibraryCount(assessmentResponse.data.testLibraryCount);
      } catch (error) {
        console.error("Error fetching counts:", error);
        setAssessmentCount(0);
        setTestLibraryCount(0);
      }
    };

    if (record && record.id) {
      fetchCounts();
    }
  }, [record.id]);

  if (property.propertyPath === 'assessmentCount') {
    return <span>{assessmentCount}</span>;
  } else if (property.propertyPath === 'testLibraryCount') {
    return <span>{testLibraryCount}</span>;
  }

  return null; 
};

export default JobRoleDetailCounting;
