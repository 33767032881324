import {ApiClient} from 'adminjs'
import React, {useState,useEffect} from "react";
import {withTheme} from 'styled-components'
import Select from 'react-select'
import {FormGroup, Label, selectStyles, Button, Icon} from '@adminjs/design-system'
const api = new ApiClient()
const BulkCsvDownload = (props) => {
    const {theme, onChange, property} = props
    const [options, setOptions] = React.useState([])
    const [testLibraryTypeOptions, setTestLibraryTypeOptions] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [questionGeneratedOptions, setQuestionGeneratedOptions] = useState([]);
    const [result, setResult] = React.useState('');
    const [formValues, setFormValues] = useState({
        testLibraryType: '',
        languages: '',
        status: '',
        questionGenerated: '',
      })
    React.useEffect(()=>{
        const fetchData = async() => {
            const response = await api.resourceAction(
                {
                    resourceId: 'TestLibrary',
                    actionName: 'fetchAllTestLibraryData',
                    data: {view: true}
                }
            )
            
            setOptions(response.data)
            
        }
        fetchData()

    },[])
    useEffect(() => {
        return () => {

            setResult('')
        }
    }, [])
    const fileDownload = (response) => {
        const blob = new Blob([response.data.res], {type: 'text/csv'});
        const downloadUrl = URL.createObjectURL(blob);
    
        const downloadLink = document.createElement('a');
        downloadLink.href = downloadUrl;
        downloadLink.download = 'result.csv';
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
    
        downloadLink.click();
    
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(downloadUrl);
    }
    
    const handleSelectChange = (field, value) => {
        setFormValues({
          ...formValues,
          [field]: value ? value.value : '',
        });
      };

    React.useEffect(()=>{
        const statusValues = ["DRAFT", "PUBLISHED", "UPCOMING"];
        const questionGeneratedValues = ["YES","NO"]
        const languageValues = [
            "English",
            "Español",
            "French",
            "Dutch",
            "Portuguese",
            "Deutsch",
            "Italian"
          ];
        const testLibraryTypeValues = [
            "Blue Collar Test",
            "Coding test",
            "Cognitive ability",
            "Engineering skills",
            "Language",
            "Personality & culture",
            "Programming skills",
            "Psychometric",
            "Role specific skills",
            "Situational Judgement",
            "Software skills"
        ]
          
         setStatusOptions(statusValues.map((status) => ({ value: status, label: status })));
         setQuestionGeneratedOptions(questionGeneratedValues.map((question)=> ({value: question, label: question})))
         setLanguageOptions(languageValues.map((language)=>({value: language, label: language})))
         setTestLibraryTypeOptions(testLibraryTypeValues.map((testLibrary)=>({value:testLibrary, label: testLibrary})));
         
    },[options])
    const submit = async() => {
        const response = await api.resourceAction(
            {
                resourceId: 'TestLibrary',
                actionName: 'BulkCsvDownload', 
                data: formValues, 

            }
        )
        fileDownload(response)
        setResult('Downloaded!')
    }
    return (
        <div>
            <FormGroup>
                {<Label required>Test Library Type</Label>}
            <Select options={testLibraryTypeOptions} isMulti ={false}
            onChange={(value) => handleSelectChange('testLibraryType', value)}/><br/>
                {<Label required>Languages</Label>}
            <Select options={languageOptions} isMulti ={false}
            onChange={(value) => handleSelectChange('languages', value)}/><br/>
                 {<Label required>Status</Label>}
            <Select options={statusOptions} isMulti ={false}
            onChange={(value) => handleSelectChange('status', value)}/><br/>
                {<Label required>Question Generated</Label>}
            <Select options={questionGeneratedOptions} isMulti ={false}
            onChange={(value) => handleSelectChange('questionGenerated', value)}/><br/>
            
            </FormGroup>
            <div style={{marginTop: '20px'}}>
                <Button style={{float: 'right'}} rounded={true} onClick={submit}>
                    <Icon icon="Download"/>
                    {'Download'}
                </Button>
                {result ? (
                    <textarea
                        disabled
                        style={{
                            marginTop: '20px',
                            width: '100%',
                            height: '100px',
                            color: '#000',
                        }}
                        value={result}
                    />
                ) : (
                    <div/>
                )}
            </div>
        </div>
        
    )
}
export default withTheme(BulkCsvDownload)