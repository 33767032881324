import {ApiClient} from 'adminjs'
import Select from 'react-select'
import {Box, Button, Header, Icon, TextArea, Loader, Label, selectStyles, FormGroup, Input} from '@adminjs/design-system'
import React from "react";
import {withTheme} from 'styled-components'

const api = new ApiClient()

const ImportJSONQuestions = (props) => {

    const [result, setResult] = React.useState("")
    const [inputJson, setInputJson] = React.useState("")
    const [loader, setLoader] = React.useState(false)
    const {theme} = props
    const [libraryId, setLibraryId] = React.useState('')

    const importJson = async (event) => {
        event.preventDefault()
        setLoader(true)
        const response = await api.resourceAction(
            {
                resourceId: 'Question',
                actionName: 'bulkAddJSON',
                data: {input: inputJson, testLibraryId: libraryId}
            }
        )
        setResult(response.data?.message || 'Something went wrong')
        setLoader(false)
    }

    return (
        <Box variant='white'>
            <form onSubmit={importJson}>
                <FormGroup>
                    <Label>TestLibrary Id</Label>
                    <Input
                        value={libraryId}
                        onChange={s => setLibraryId(s.target.value)}
                    />
                </FormGroup>
                <Label required>JSON dump</Label>
                <TextArea
                    required={true}
                    disabled={loader}
                    onChange={(event) => setInputJson(event.target.value)}
                    placeholder="Paste the JSON here!"
                    style={{width: '100%', height: '200px', marginBottom: '20px'}}
                />
                <div style={{marginBottom: '20px'}}>
                    <Button style={{float: 'right'}} rounded={true} type='submit'>
                        <Icon icon="WorkspaceImport"/>
                        Import
                    </Button>
                    {loader
                        ? <Loader/>
                        : result.length > 0 ? <TextArea
                            disabled
                            style={{
                                marginTop: '20px',
                                width: '100%',
                                height: '100px',
                                color: '#000'
                            }}
                            value={result}
                        /> : <div/>
                    }
                </div>
            </form>
        </Box>
    )
}

export default withTheme(ImportJSONQuestions)
