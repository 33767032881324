import {ApiClient} from 'adminjs'
import React from "react";
import {withTheme} from 'styled-components'
import Select from 'react-select'
import {FormGroup, Label, selectStyles, Button, Icon} from '@adminjs/design-system'
const api = new ApiClient()
const MigrateAssessment = (props) => {
    const {theme, onChange, property} = props
    const [options, setOptions] = React.useState([])
    const [assessment, setAssessment] = React.useState([])
    const [workspaceId, setWorkspaceId] = React.useState()
    const [filteredOptions, setFilteredOptions] = React.useState([])
    const [assessmentOptions, setAssessmentOptions] = React.useState([])
    const [result, setResult] = React.useState('');
    const [fromOrgId, setFromOrgId] = React.useState(''); 
    const [toOrgId, setToOrgId] = React.useState(''); 
    const [selectedAssessments, setSelectedAssessments] = React.useState([]);
    React.useEffect(()=>{
        const fetchData = async() => {
            const response = await api.resourceAction(
                {
                    resourceId: 'Organization',
                    actionName: 'fetchAllOrganization',
                    data: {view: true}
                }
            )
            setOptions(response.data)
        }
        fetchData()
    },[])
    React.useEffect(()=>{
        const fetchAssessmentData = async() => {
            const response = await api.resourceAction(
                {
                    resourceId: 'Organization',
                    actionName: 'fetchAssessmentData',
                    data: {workspaceId: workspaceId.value}
                }
            )
           setAssessment(response.data)
        }
        fetchAssessmentData()
    },[workspaceId,filteredOptions])
    React.useEffect(()=>{
        setFilteredOptions(options.map((e)=>({value:e._id,label:e.workspaceUrl})))
        setAssessmentOptions(assessment.map((e)=>({value:e._id,label:e.title})))
    },[options,assessment])
   
    const submit = async() => {
        const response = await api.resourceAction(
            {
                resourceId: 'Organization',
                actionName: 'migrateAssessment',
                data: {
                    fromOrgId: fromOrgId, 
                    toOrgId: toOrgId,
                    selectedAssessments: selectedAssessments
                }
            }
        )
        setResult(`${response.data}`)
    }
    return (
        <div>
            <FormGroup>
                {<Label required>From Organization</Label>}
            <Select options={filteredOptions} isMulti ={false}
            onChange={(value)=>{
                setFromOrgId(value.value)
                setWorkspaceId(value)
            }}/><br/>
            {<Label required>Select Assessment</Label>}
            <Select options={assessmentOptions} isMulti ={true}
            onChange={(selectedValues)=>{
                setSelectedAssessments(
                    selectedValues.map((value) => ({
                      value: value.value,
                      label: value.label,
                    }))
                  );
                }}/><br/>
                {<Label required>To Organization</Label>}
            <Select options={filteredOptions} isMulti ={false}
            onChange={(value)=>{
                setToOrgId(value.value);
            }}/>
            </FormGroup>
            <div style={{marginTop: '20px'}}>
                <Button style={{float: 'right'}} rounded={true} onClick={submit}>
                    <Icon icon="Download"/>
                    {'Migrate'}
                </Button>
                {result ? (
                    <textarea
                        disabled
                        style={{
                            marginTop: '20px',
                            width: '100%',
                            height: '100px',
                            color: '#000',
                        }}
                        value={result}
                    />
                ) : (
                    <div/>
                )}
            </div>
        </div>
        
    )
}
export default withTheme(MigrateAssessment)