export const LANGUAGE_ENUMS = {
        Vietnamese: "Vietnamese",
        Turkish: "Turkish",
        Swedish: "Swedish",
        Spanish: "Spanish",
        Russian: "Russian",
        "Portuguese (Brazil)": "Portuguese (Brazil)",
        Portuguese: "Portuguese",
        Polish: "Polish",
        Norwegian: "Norwegian",
        Marathi: "Marathi",
        Mandarin: "Mandarin",
        Japanese: "Japanese",
        Korean: "Korean",
        Italian: "Italian",
        Indonesian: "Indonesian",
        Hindi: "Hindi",
        Hebrew: "Hebrew",
        Greek: "Greek",
        German: "German",
        French: "French",
        Dutch: "Dutch",
        Danish: "Danish",
        Arabic: "Arabic",
        English: "English",
        Español: "Español",
}

export const LANGUAGE_CODES = {
        "ar": "Arabic",
        "cs": "Czech",
        "da": "Danish",
        "de": "German",
        "en": "English",
        "es": "Spanish",
        "fi": "Finnish",
        "fr": "French",
        "hi": "Hindi",
        "in": "Indonesian",
        "it": "Italian",
        "ja": "Japanese",
        "ko": "Korean",
        "mr": "Marathi",
        "ms": "Malaysian",
        "nl": "Dutch",
        "no": "Norwegian",
        "pl": "Polish",
        "pt-BR": "Portuguese (Brazil)",
        "pt-PT": "Portuguese",
        "ro": "Romanian",
        "ru": "Russian",
        "sv": "Swedish",
        "th": "Thai",
        "tl": "Filipino",
        "tr": "Turkish",
        "zh": "Chinese",
}
