import {ApiClient} from 'adminjs';
import React, {useEffect} from 'react';
import {withTheme} from 'styled-components';
import {FormGroup, Label, Button, Icon, Loader, selectStyles,Box} from '@adminjs/design-system';
import mongoose from 'mongoose'
const api = new ApiClient();

const assessmentWorkspace = (props) => {
    const {theme, records, action} = props;
    const [oId, setOId] = React.useState('');
    const [result, setResult] = React.useState('');
    useEffect(() => {
        return () => {

            setResult('')
        }
    }, [])
    const submit = async () => {
        if (!oId) {
            setResult('Please provide a value.')
        } else if (!mongoose.Types.ObjectId.isValid(oId)) {
            setResult('Please ensure you provide a valid AssessmentId.');
        } else {
            const response = await api.resourceAction(
                {
                    resourceId: 'Organization',
                    actionName: 'workspaceNameByAssessmentID', 
                    data: {testLibraryId: oId}, 
    
                }
            )
            setResult(`${response.data}`)
        }
    };

    return (
        <Box variant='white'>
            <FormGroup>
                <Label>Workspace Name From Assessment ID</Label>
                <input
                    type="text"
                    value={oId}
                    onChange={(e) => setOId(e.target.value)}
                    style={{width: '100%', padding:"8px"}}
                />
            </FormGroup>
            <div style={{marginTop: '20px', marginBottom: "40px"}}>
                <Button style={{float: 'right'}} rounded={true} onClick={submit}>
                    <Icon icon="Migrate"/>
                    {'Search Workspace Name Using AssessmentId'}
                </Button>
                {result ? (
                    <textarea
                        disabled
                        style={{
                            marginTop: '20px',
                            width: '100%',
                            height: '100px',
                            color: '#000',
                        }}
                        value={result}
                    />
                ) : (
                    <div/>
                )}
            </div>

        </Box>
    );
};

export default withTheme(assessmentWorkspace)