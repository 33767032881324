import { ApiClient } from 'adminjs'
import { Box, Button, Icon, TextArea, Loader, Label } from '@adminjs/design-system'
import React from "react";
import { withTheme } from 'styled-components'

const api = new ApiClient()

const ImportQuestions = (props) => {
    const { resource } = props
    const [csv, setCsv] = React.useState(null)
    const [result, setResult] = React.useState("")
    const [loader, setLoader] = React.useState(false)
    const [status, setStatus] = React.useState(false)
    const [fileName, setFileName] = React.useState("")

    const importJson = async (event) => {
        event.preventDefault()
        setLoader(true)
        new Papa.parse(csv, {
            complete: async function (results) {
                const response = await api.resourceAction(
                    {
                        resourceId: (resource.name == 'Language') ? 'Language' : 'Question',
                        actionName: 'csvBulkEdit',
                        data: { input: results.data }
                    }
                )
                setResult(response.data?.message || 'Something went wrong')
                setStatus(true)
                setLoader(false)
            }
        })
    }

    return (
        <Box variant='white' style={{ padding: '40px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
            <form onSubmit={importJson}>
                <Label required style={{ fontSize: '16px', lineHeight: '20px', marginBlock: '12px' }}>CSV Upload</Label>
                <div style={{ marginBlock: '20px' }}>
                    <Label
                        style={{
                            width: 130,
                            height: 25,
                            fontSize: 15,
                            cursor: 'pointer',
                            background: '#4268f6',
                            textAlign: 'center',
                            padding: '5px 0',
                            color: '#fff',
                            borderRadius: '4px',
                            display: resource.id === 'Question' ? 'block' : 'none',
                            marginBlock: '10px',
                            alignItems: 'center'
                        }}
                        rounded={false}
                    >
                        <a href="https://docs.google.com/spreadsheets/d/e/2PACX-1vTus1ilHIDPfq6WT-WG3VEKBX6LJ7mzJupQzp1BNcdL4LEfs9YCcVByRGmwrcRq3D7RkT8CvqVn4tgG/pub?gid=860529776&single=true&output=csv"
                            style={{ textDecoration: 'none', color: '#fff' }}
                            download="sample csv">
                            Sample CSV
                        </a>
                    </Label>
                    <Label for='files'
                        style={{
                            width: 130,
                            height: 25,
                            fontSize: 15,
                            cursor: 'pointer',
                            background: '#de3439',
                            textAlign: 'center',
                            padding: '5px 0',
                            color: '#fff',
                            borderRadius: '4px',
                            marginBlock: '10px',
                            alignItems: 'center'
                        }}>
                        Select File
                        <input id="files" accept=".csv" style={{ display: 'none' }} onChange={(event) => { setCsv(event.target.files[0]); setFileName(event.target.files[0].name); setStatus(false) }} type="file" />
                    </Label>
                    <span style={{ marginLeft: '10px', fontSize: '14px' }}>{fileName}</span>
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <Button disabled={status} style={{ border: '1px solid #f23f44', float: 'right', marginBottom: '20px' }} rounded type='submit'>
                        <Icon icon="WorkspaceImport" />
                        Edit Csv
                    </Button>
                    {loader
                        ? <Loader />
                        : result.length > 0 ? <TextArea
                            disabled
                            style={{
                                marginTop: '20px',
                                width: '100%',
                                height: '100px',
                                color: '#000',
                                borderRadius: '4px',
                                border: '1px solid #ced4da'
                            }}
                            value={result}
                        /> : <div />
                    }
                </div>
            </form>
        </Box>
    )
}

export default withTheme(ImportQuestions)
