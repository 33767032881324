import {ApiClient} from 'adminjs'
import React from "react";
import {withTheme} from 'styled-components'
import Select from 'react-select'
import {FormGroup, Label, selectStyles} from '@adminjs/design-system'
const api = new ApiClient()
const JobRole = (props) => {
    const {theme, onChange, property, filter} = props
    const [options, setOptions] = React.useState([])
    const [filteredOptions, setFilteredOptions] = React.useState([])

    React.useEffect(()=>{
        const fetchData = async() => {
            const response = await api.resourceAction(
                {
                    resourceId: 'TestLibrary',
                    actionName: 'getJobRole',
                    data: {new: true}
                }
            )
            setOptions(response.data)
        }
        fetchData();
    },[])

    const handleInputChange = (value) => {
        setFilteredOptions([]);
        if(value.length > 1){
            const filtered = options.filter( (i) => i.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()) );
            setFilteredOptions(filtered.map((e)=>({value:e.code, label:e.name})));
        }
    };

    const handleSelectChange = (selected) => {
        onChange(property.path, selected?.value ?? '')
    }
    
    return (
        <div>
            <FormGroup>
            <Label>Job Role</Label>
            <Select options={filteredOptions}
            placeholder='Please enter min 2 keywords'
            onChange={handleSelectChange}
            onInputChange={handleInputChange}
            />
            </FormGroup>
        </div>
    )
}
export default withTheme(JobRole)