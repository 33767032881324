import { ApiClient } from 'adminjs';
import React, { useState, useEffect, useRef } from 'react';
import { withTheme } from 'styled-components';
import Select from 'react-select';
import {
  FormGroup,
  Label,
  selectStyles,
  Button,
  Icon,
  Loader,
  Modal,
  Table
} from '@adminjs/design-system';
const api = new ApiClient();

const Toast = ({ message, type, onClose }) => {
    return (
      <div
        style={{
          position: 'fixed',
          bottom: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          padding: '10px',
          borderRadius: '5px',
          backgroundColor: type === 'success' ? 'green' : 'red',
          color: 'white',
          zIndex: '1000',
        }}
      >
        {message}
        <button style={{ marginLeft: '10px' }} onClick={onClose}>
          Close
        </button>
      </div>
    );
  };

  const ApprovalPopup = ({ data, onClose }) => {
    return (
      <Modal
        title="Approval Details"
        onClose={onClose}
        variant="primary"
        size="lg"
      >
        <Table>
          <thead>
            <tr>
              <th>Language</th>
              <th>ID</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td>{item.language}</td>
                <td>{item.id}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Button onClick={onClose} mt="lg">
          Close
        </Button>
      </Modal>
    );
  };



const RephraseQuestions = (props) => {
  const { theme, onChange, property } = props;
  const [options, setOptions] = React.useState([]);
  const [filteredOptions, setFilteredOptions] = React.useState([]);
  const [promptOptions, setPromptOptions] = useState([]);
  const [result, setResult] = React.useState('');
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);
  const [fromOrgId, setFromOrgId] = useState('');
  const [toOrgId, setToOrgId] = useState('');
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [getResponse, setGetResponse] = useState([]);
  const [generateResponse, setGenerateResponse] = useState([]);
  const [chatGptPrompt, setChatGptPrompt] = useState('');
  const [selectedChatGptPrompt, setSelectedChatGptPrompt] = useState(null);
  const [selectedQuestionId, setSelectedQuestionId] = useState('');
  const [searchQuery, setSearchQuery] = React.useState('');
  const [defaultValues, setDefaultValues] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [regeneratedResponse, setRegeneratedResponse] = useState([]);
  const [toast, setToast] = useState(null);
  const selectref = useRef(null);
  const [isRephraseButtonDisabled, setIsRephraseButtonDisabled] = useState(true);
  const [isRephraseButtonClicked, setIsRephraseButtonClicked] = useState(false);
  const [approvalDetails, setApprovalDetails] = useState(null);
  const [extractedUrl, setExtractedUrl] = useState(null);



  const resetState = () => {
    setOptions([]);
    setFilteredOptions([]);
    setResult('');
    setSecondSelectOptions([]);
    setFromOrgId('');
    setToOrgId('');
    setSelectedQuestion(null);
    setExtractedUrl(null);
    setSelectedOptions([]);
    setGetResponse([]);
    setGenerateResponse([]);
    setChatGptPrompt('');
    setSelectedQuestionId('');
    setSearchQuery('');
    setDefaultValues([]);
    setLoading(false);
    setRegeneratedResponse([]);
  };

  React.useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const response = await api.resourceAction({
          resourceId: 'TestLibrary',
          actionName: 'getAllTestLibraryList',
          data: {
            limit: 100000,
            skip: 0,
            query: searchQuery,
          },
        });

        if (isMounted) {
          const optionsArray = response.data.records.map((item) => ({
            value: item._id,
            label: item.title,
          }));
          setOptions(optionsArray);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [searchQuery]);

  useEffect(() => {
    const fetchPromptOptions = async () => {
        try {
          const response = await api.resourceAction({
            resourceId: 'TestLibrary',
            actionName: 'fetchAllPromptData',
          });
          const options = response.data.map(prompt => ({
            label: prompt.prompt,
            value: prompt._id
        }));
          setPromptOptions(options);
        } catch (error) {
            console.error('Error fetching prompt options:', error);
        }
    };

    fetchPromptOptions(); 
}, []);

  const handleChatGptPromptChange = (e) => {
    setSelectedChatGptPrompt(e.label);
  };
  useEffect(() => {
    if (selectedChatGptPrompt !== null) {
      updateRephraseButtonStatus(fromOrgId, selectedChatGptPrompt);
    }
  }, [selectedChatGptPrompt]);

  const handleGenerateClick = async () => {
    setLoading(true); // loading true
    try {
      await generateApiCall(chatGptPrompt);
    } catch (error) {
      console.error('Error generating data:', error);
    } finally {
      setLoading(false); // loading false
    }
  };
  const updateRephraseButtonStatus = (selectedValue, chatGptPromptValue) => {
    const isDisabled = !selectedValue || !chatGptPromptValue;
    setIsRephraseButtonDisabled(isDisabled);
  };

  const showToast = (message, type = 'success') => {
    setToast({ message, type });
    setTimeout(() => setToast(null), 3000); 
  };

  const handleApprovalPopupClose = () => {
    setApprovalDetails(null);
  };

  const approveApiCall = async () => {
    const formattedQuestions = generateResponse.map((questionData) => {
        const formattedOptions = questionData.options.map((option) => ({
          id: option._id,
          description: option.description,
          isCorrect: option.isCorrect,
        }));
  
        return {
          id: questionData.id,
          options: formattedOptions,
          language: questionData.language,
          question: questionData.question,
        };
      });
      try {
        const response = await api.resourceAction({
          resourceId: 'TestLibrary',
          actionName: 'saveRephrasedQuestions',
          data: {
            questions: formattedQuestions,
          },
        });

        setApprovalDetails(response.data);

        showToast('Questions approved successfully', 'success');
        resetState();
        setFromOrgId('');
        setChatGptPrompt('');

      } catch (error) {
        console.error('Error approving questions:', error);
        showToast('Error approving questions. Please try again.', 'error');

      }
  }


  const generateApiCall = async (questionId) => {
    try {
      const generateResponse = await api.resourceAction({
        resourceId: 'TestLibrary',
        actionName: 'rephraseQuestionWithAllLanguages',
        data: {
          questionId: selectedQuestionId,
          prompt: selectedChatGptPrompt
        //   prompt: `Rephrase the following {language} multiple-choice question to increase the length of the question and options, and improve grammar, sentence structure, and cultural appropriateness while preserving its technical accuracy and professional tone. Ensure that the rephrased question is culturally appropriate and suitable for native-speaking individuals. Pay attention to the clarity and precision of the {language} language..`,
        },
      });  
      if (!generateResponse.data || !Array.isArray(generateResponse.data)) {
        throw new Error('Unexpected response format');
      }
      setGenerateResponse(generateResponse.data);
      setIsRephraseButtonClicked(true); 
      showToast('Question generated successfully', 'success');

    } catch (error) {
      console.error('Error generating data:', error);
      if (generateResponse.status === 500) {
        showToast('Internal server error. Please try again later.', 'error');
      } else {
        showToast('Error generating question. Please try again.', 'error');
      }
    }
  };
  

  const handleReGenerateClick = async (questionId, language) => {
    if (questionId) {
      setLoading(true); // loading true
      try {
        await regenerateApiCall(questionId, language);
      } catch (error) {
        console.error('Error regenerating data:', error);
      } finally {
        setLoading(false); // loading false
      }
    } else {
      console.error('No question ID provided for regeneration');
    }
  };

  const regenerateApiCall = async (questionId, language) => {
    try {
      const reGenerateResponse = await api.resourceAction({
        resourceId: 'TestLibrary',
        actionName: 'rephraseQuestionWithSingleLanguage',
        data: {
          questionId: questionId,
          language: language,
          prompt: selectedChatGptPrompt,
        //   prompt: `Rephrase the following {language} multiple-choice question to increase the length of the question and options, and improve grammar, sentence structure, and cultural appropriateness while preserving its technical accuracy and professional tone. Ensure that the rephrased question is culturally appropriate and suitable for native-speaking individuals. Pay attention to the clarity and precision of the {language} language.`,
        },
      });
      setGenerateResponse((prevGenerateResponse) => {
        const updatedGenerateResponse = [...prevGenerateResponse];
        const questionIndex = updatedGenerateResponse.findIndex(
          (question) => question.id === questionId
        );

        if (questionIndex !== -1) {
          updatedGenerateResponse[questionIndex] = {
            ...updatedGenerateResponse[questionIndex],
            question: reGenerateResponse.data.question,
          };
        } else {
          console.error('Question not found in generateResponse:', questionId);
        }

        return updatedGenerateResponse;
      });
      showToast('Question regenerated successfully', 'success');

    } catch (error) {
      console.error('Error regenerating data:', error);
      showToast('Error regenerating question. Please try again.', 'error');
      if (regeneratedResponse.status === 500) {
        showToast('Internal server error. Please try again later.', 'error');
      } else {
        showToast('Error regenerating question. Please try again.', 'error');
      }
    }
  };

  const handleQuestionChange = (e, questionId) => {
    const updatedGenerateResponse = generateResponse.map((question) =>
      question.id === questionId
        ? { ...question, question: e.target.value }
        : question
    );
    setGenerateResponse(updatedGenerateResponse);
  };

  const handleOptionChange = (e, questionId, optionIndex) => {
    const updatedGenerateResponse = generateResponse.map((question) =>
      question.id === questionId
        ? {
            ...question,
            options: question.options.map((option, index) =>
              index === optionIndex
                ? { ...option, description: e.target.value }
                : option
            ),
          }
        : question
    );
    setGenerateResponse(updatedGenerateResponse);
  };

  const fetchOptionData = async (selectedId) => {
    const getResponse = await api.resourceAction({
      resourceId: 'TestLibrary',
      actionName: 'getQuestionsOfTestLibrary',
      data: {
        testLibraryId: selectedId,
      },
    });

    setGetResponse(getResponse.data);

    const newOptions = getResponse.data.map((question) => ({
      value: question._id,
      label: question.question,
    }));
    setSecondSelectOptions(newOptions);
  };

  const handleSelectChange = (value) => {
    setFromOrgId(value.value);
    fetchOptionData(value.value);
    updateRephraseButtonStatus(value.value, chatGptPrompt);

  };

  const handleSecondSelectChange = (value) => {
    const selectedQuestionId = value.value;
    setSelectedQuestionId(value.value);
    setSelectedQuestion(value);
    setGenerateResponse([]);
    const urlPattern = /\((.*?)\)/;
    const urlMatch = value.label.match(urlPattern);
    if (urlMatch) {
      const url = urlMatch[1];
      setExtractedUrl(url);
    } else {
      setExtractedUrl(null);
    }
    const selectedQuestionData = getResponse.find(
      (item) => item._id === selectedQuestionId
    );
    if (selectedQuestionData) {
      setSelectedOptions(
        selectedQuestionData.options.map((option) => {
          return option;
        })
      );
    } else {
      setSelectedOptions([]);
    }
  };

  return (
    <>
    {approvalDetails && (
        <ApprovalPopup
          data={approvalDetails}
          onClose={handleApprovalPopupClose}
        />
      )}
        <FormGroup style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div style={{ display: 'flex', gap: '2px' }}>
            <div style={{ flex: 1 }}>
            <Label required style={{ fontSize: '16px' }}>
                Test Library Name
            </Label>
            <Select
                options={options}
                isMulti={false}
                value={fromOrgId
                    ? options.find((option) => option.value === fromOrgId)
                    : null}
                styles={{
                container: (provided) => ({
                    ...provided,
                    width: '200px',
                }),
                }}
                onChange={handleSelectChange}
                onInputChange={(inputValue) => setSearchQuery(inputValue)}
            />
            </div>
            <div style={{ flex: 1 }}>
            <Label required style={{ fontSize: '16px' }}>
                Select Question
            </Label>
            <Select
                options={secondSelectOptions}
                isMulti={false}
                value={selectedQuestionId
                    ? options.find((option) => option.value === selectedQuestionId)
                    : null}
                styles={{
                container: (provided) => ({
                    ...provided,
                    width: '200px',
                }),
                }}
                onChange={handleSecondSelectChange}
            />
            </div>
            <div style={{ flex: 1 }}>
        <Label style={{ fontSize: '16px' }}>
            Chat GPT Prompt
        </Label>
        <Select
            options={promptOptions}
            isMulti={false}
            value={selectedChatGptPrompt ? promptOptions.find(option => option.value === selectedChatGptPrompt) : null}
            styles={{
                container: (provided) => ({
                    ...provided,
                    width: '300px',
                }),
            }}
            onChange={handleChatGptPromptChange}
        />
         </div>
            {loading && (
            <div
                style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                }}
            >
                <Loader />
            </div>
            )}
            <div style={{ flex: 1 }}>
            <Button style={{ float: 'right' }} 
            rounded={true} 
            onClick={handleGenerateClick} 
            disabled={isRephraseButtonDisabled}>  
                {'Rephrase'}
            </Button>
            </div>
        </div>

        {
            <Label required style={{ fontSize: '16px', marginTop: '20px' }}>
            Original
            </Label>
        }
        <div>
            <div>
            {selectedQuestion && (
                <div>
                <div style={{
                            background: extractedUrl ? 'white' : 'transparent',
                            borderRadius: '4px',
                            border: extractedUrl ? '1px solid #ccc' : 'none',
                            marginBottom: '10px',
                        }}>
                    <textarea
                        rows={3}
                        value={selectedQuestion.label}
                        onChange={(e) => handleQuestionChange(e, selectedQuestionId)}
                        style={{
                            width: '100%',
                            padding: '8px',
                            boxSizing: 'border-box',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                            fontSize: '16px',
                            maxWidth: '100%',
                        }}
                    />
                  {extractedUrl && (
                  <div>
                    <img
                      src={extractedUrl}
                      alt="Image"
                      style={{ maxWidth: '100%', marginBottom: '10px', height: 'auto', maxHeight:'400px'}}
                    />
                  </div>
                )}
                </div>
                <div style={{ display: 'flex', gap: '5px' }}>
                {selectedOptions.map((option, index) => (
                    <div key={index} style={{ flex: 1 }}>
                        <textarea
                            rows={2}
                            value={option.description}
                            onChange={(e) => handleOptionChange(e, selectedQuestionId, index)}
                            style={{
                                width: '100%',
                                padding: '8px',
                                boxSizing: 'border-box',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                                fontSize: '14px',
                                color: option.isCorrect ? 'green' : 'red',

                            }}
                        />
                    </div>
                ))}
            </div>
                </div>
            )}
            </div>
        </div>

        <div>
            {generateResponse.slice().reverse().map((questionData, index) => (
                
            <div style={{ marginTop: '70px' }} key={index}>
                {
                <Label style={{ fontSize: '16px' }} required>{questionData.language}</Label>
                }
                <div style={{
                            background: extractedUrl ? 'white' : 'transparent',
                            borderRadius: '4px',
                            border: extractedUrl ? '1px solid #ccc' : 'none',
                            marginBottom: '10px',
                        }}>
                <textarea
                    rows={3}
                    value={questionData.question}
                    onChange={(e) => handleQuestionChange(e, questionData.id)}
                    style={{
                    width: '100%',
                    padding: '8px',
                    boxSizing: 'border-box',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                    maxWidth: '100%',
                    }}
                />
                {extractedUrl && (
                  <div>
                    <img
                      src={extractedUrl}
                      alt="Extracted Image"
                      style={{ maxWidth: '100%', marginBottom: '10px', height: 'auto', maxHeight:'400px'}}
                    />
                  </div>
                )}
                </div>
                <div style={{ display: 'flex', gap: '20px', marginTop: '10px' }}>
                {questionData.options.slice(0, 2).map((option, optionIndex) => (
                    <div key={optionIndex} style={{ flex: 1 }}>
                    <input
                        type="text"
                        defaultValue={option.description}
                        onChange={(e) => {
                        // Handle input
                        }}
                        style={{
                        width: '100%',
                        padding: '8px',
                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                        color: option.isCorrect ? 'green' : 'red',
                        }}
                    />
                    </div>
                ))}
                </div>
                <div style={{ display: 'flex', gap: '20px', marginTop: '10px' }}>
                {questionData.options.slice(2, 4).map((option, optionIndex) => (
                    <div key={optionIndex} style={{ flex: 1 }}>
                    <input
                        type="text"
                        defaultValue={option.description}
                        onChange={(e) => {
                        // Handle input
                        }}
                        style={{
                        width: '100%',
                        padding: '8px',
                        boxSizing: 'border-box',
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                        color: option.isCorrect ? 'green' : 'red',
                        }}
                    />
                    </div>
                ))}
                </div>
                <div style={{ flex: 1, marginTop: '10px' }}>
                <Button
                    style={{ float: 'right' }}
                    rounded={true}
                    onClick={() => handleReGenerateClick(questionData.id, questionData.language)}
                >
                    {'Re-Generate'}
                </Button>
                </div>
            </div>
            ))}
        </div>

        <div style={{ marginTop: '40px' }}>
            <Button style={{ float: 'right' }} 
            rounded={true} 
            onClick={approveApiCall}
            disabled={!isRephraseButtonClicked}>
            {'Approve'}
            </Button>
        </div>
        </FormGroup>
        {toast && (
                <Toast
                message={toast.message}
                type={toast.type}
                onClose={() => setToast(null)}
                />
            )}
    </>

  );
};
export default withTheme(RephraseQuestions);
