import React, { useEffect, useState } from 'react';
import { ApiClient } from 'adminjs';

const CommentBar = () => {
    const [comments, setComments] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [adminId, setAdminId] = useState('')

    const getAction = () => {
        const urlSegments = window.location.pathname.split('/');
        const questionIdIndex = urlSegments.indexOf('records') + 2;
        return urlSegments[questionIdIndex];
    };
    const action = getAction();

    const getIdFromUrl = () => {
        const urlSegments = window.location.pathname.split('/');
        const questionIdIndex = urlSegments.indexOf('records') + 1;
        return urlSegments[questionIdIndex];
    };

    const getAdminId = async () => {
        try {
            const response = await api.resourceAction({
                resourceId: 'Question',
                actionName: 'getAdminId',
            })
            setAdminId(response.data)
        } catch (error) {
            console.error('Error fetching AdminId:', error);
        }
    }
    useEffect(() => {
        getAdminId();
    }, []);

    const fetchComments = async () => {
        try {
            const questionId = getIdFromUrl();
            const response = await api.resourceAction({
                resourceId: 'Question',
                actionName: 'getComments',  
                data: {
                    questionId: questionId
                }
            });
            setComments(response.data);
        } catch (error) {
            console.error('Error fetching comments:', error);
        }
    };

      useEffect(() => {
        fetchComments();
    }, []);

    const api = new ApiClient();

    const handleAddComment = async () => {
        event.preventDefault();
        event.stopPropagation();
        try {
            const questionId = getIdFromUrl();

            const response = await api.resourceAction({
                resourceId: 'Question',
                actionName: 'addComments',
                data: {
                    comment: inputValue,
                    questionId: questionId
                }
            });
            setInputValue('');
            fetchComments();
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };

    const deleteComment = async (commentId) => {
        event.preventDefault();
        event.stopPropagation();
        try {
            const questionId = getIdFromUrl();
            const response = await api.resourceAction({
                resourceId: 'Question',
                actionName: 'deleteComment',
                data: {
                    commentId: commentId,
                    questionId: questionId
                }
            });
            fetchComments();
        } catch (error) {
            console.error('Error deleting comment:', error);
        }
    };

return (
    <div>
        {action === 'show' ? (
            <div>
                <div style={{ marginTop: '20px' }}>
                    <h3 style={{ marginBottom: '10px' }}>Previous Comments</h3>
                    {comments.length > 0 ? (
                        <ul>
                            {comments.map((comment, index) => (
                                <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px', marginBottom: '10px' }}>
                                    <li style={{ border: '1px solid #000', padding: '8px', color: 'blue', fontWeight: '600', flex: '1', display: 'flex', gap: '4px' }}>
                                        {comment.username}: <span style={{ color: '#000', fontWeight: '400', flex: '1' }}>{comment.comment}</span> <span style={{ color: '#000', fontWeight: '400' }}>{comment.time}</span>
                                    </li>
                                </div>
                            ))}
                        </ul>
                    ) : (
                        <p>No comments yet.</p>
                    )}
                </div>
            </div>
        ) : action === 'edit' ? (
            <div style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '5px' }}>
                <h2 style={{ marginBottom: '10px' }}>Comment Section</h2>
                <div style={{ display: 'flex', marginBottom: '10px' }}>
                    <input
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder="Type your comment here..."
                        style={{ flex: 1, padding: '10px', marginRight: '10px' }}
                    />
                    <button onClick={handleAddComment} style={{ padding: '10px' }}>
                        Add Comment
                    </button>
                </div>
                <div>
                    <div style={{ marginTop: '20px' }}>
                        <h3 style={{ marginBottom: '10px' }}>Previous Comments</h3>
                        {comments.length > 0 ? (
                            <ul>
                                {comments.map((comment, index) => (
                                    <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px', marginBottom: '10px' }}>
                                        <li style={{ border: '1px solid #000', padding: '8px', color: 'blue', fontWeight: '600', flex: '1', display: 'flex', gap: '4px' }}>
                                            {comment.username}: <span style={{ color: '#000', fontWeight: '400', flex: '1' }}>{comment.comment}</span> <span style={{ color: '#000', fontWeight: '400' }}>{comment.time}</span>
                                        </li>
                                        {comment.userId === adminId && (
                                            <button onClick={() => deleteComment(comment._id)} style={{ padding: '8px' }}>
                                                Delete
                                            </button>
                                        )}
                                    </div>
                                ))}
                            </ul>
                        ) : (
                            <p>No comments yet.</p>
                        )}
                    </div>
                </div>
            </div>
        ) : ""}
    </div>
);
};

export default CommentBar;