import {Header, Box} from '@adminjs/design-system'
import { useCurrentAdmin, useNotice } from "adminjs";
import React, { useEffect } from "react";
import * as FullStory from '@fullstory/browser';

const Dashboard = () => {
    const isBot = /bot|google|baidu|bing|msn|teoma|slurp|yandex/i.test(navigator.userAgent)
    const [currentAdmin] = useCurrentAdmin();

    useEffect(() => {
        if (!isBot) {
            FullStory.init({orgId: '17J37W'})
            FullStory.identify(currentAdmin.id, {
                displayName: currentAdmin.fullName,
                email: currentAdmin.email
            })
        }
    }, [])

    const query = new URLSearchParams(window.location.search);
    const noticeMessage = query.get("noticeMessage");
    const noticeType = query.get("noticeType");
    const sendNotice = useNotice();

    useEffect(() => {
      if (noticeMessage) {
        sendNotice({ message: noticeMessage, type: noticeType || "success" });
      }
    }, [noticeMessage, noticeType]);

    return (
        <Box variant="grey">
            <Box variant="grey">
                <Header.H2 style={{textAlign: 'center'}}>Welcome to Testlify
                    Backoffice</Header.H2>
            </Box>
        </Box>
    )
}

export default Dashboard