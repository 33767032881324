import { ApiClient } from 'adminjs';
import React, { useEffect, useState } from 'react';
import { withTheme } from 'styled-components';
import Select from 'react-select';
import { FormGroup, Label, selectStyles } from '@adminjs/design-system';

const api = new ApiClient();

const SkillDescription = (props) => {
    const { theme, onChange, property } = props;
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const { record } = props;

    useEffect(() => {
        const testLibraryId = record.params.testLibraryId;
        const fetchSkillDescription = async () => {
            const response = await api.resourceAction({
                resourceId: 'TestLibrarySkillData',
                actionName: 'fetchSkills',
                data: { testLibraryId: testLibraryId }
            });

            const skillDescriptions = response.data.skills.map(skill => ({
                value: skill._id.toString(),
                label: skill.description
            }));

            setOptions(skillDescriptions);

            const skillId = record.params.skillId;
            if (skillId) {
                const selectedOption = skillDescriptions.find(option => option.value === skillId);
                setSelectedOption(selectedOption);
            }
        };

        fetchSkillDescription();
    }, [record]);

    return (
        <div>
            <FormGroup>
                {
                    props.where !== 'filter' ?
                    <Label>Skill Name</Label>
                    : <Label>Skill Name</Label>
                }
            <Select
                    options={options}
                    value={selectedOption}
                    mode="single"
                    onChange={(selectedOption) => {
                        const selectedValue = selectedOption ? selectedOption.value : [];
                        onChange(property.path, selectedValue);
                    }}
                />
            </FormGroup>
        </div>
    );
};

export default withTheme(SkillDescription);
