import {ApiClient } from 'adminjs';
import { Box, Button, Icon, TextArea, Loader, Label } from '@adminjs/design-system';
import React, { useEffect, useRef } from 'react';
import { withTheme } from 'styled-components';

const api = new ApiClient();

const ImportTranslations = (props) => {
    const { action } = props;
    const [csv, setCsv] = React.useState(null);
    const [result, setResult] = React.useState('');
    const [loader, setLoader] = React.useState(false);
    const [status, setStatus] = React.useState(false);
    const [fileName, setFileName] = React.useState('');
    const fileInputRef = useRef(null); // Ref for file input

    useEffect(() => {
        // Clear the state when action.name changes
        setResult('');
        setFileName('');
        setCsv(null);
        setStatus(false);
        setLoader(false);

        // Reset the file input value
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    }, [action.name]);

    const importJson = async (event) => {
        event.preventDefault();
        setLoader(true);
        let response;
        const file = await convertBase64(csv);

        if (action.name === 'candidateCSVImport') {
            response = await api.resourceAction({
                resourceId: 'MultilingualTranslation',
                actionName: 'candidateCSVImport',
                data: {
                    type: 'CANDIDATE',
                    csv: file,
                },
            });
        } else if (action.name === 'employerCSVImport') {
            response = await api.resourceAction({
                resourceId: 'MultilingualTranslation',
                actionName: 'employerCSVImport',
                data: {
                    type: 'EMPLOYER',
                    csv: file,
                },
            });
        }

        setResult(response?.data || 'Something went wrong');
        setStatus(true);
        setLoader(false);
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    return (
        <Box variant="white">
            <form onSubmit={importJson}>
                <Label required>
                    {action.name === 'candidateCSVImport'
                        ? 'Upload CSV file for Candidate side to convert it to JSON'
                        : 'Upload CSV file for Employer side to convert it to JSON'}
                </Label>
                <div>
                    <Label
                        htmlFor="files"
                        style={{
                            width: 130,
                            height: 25,
                            fontSize: 15,
                            cursor: 'pointer',
                            background: '#F23F44',
                            textAlign: 'center',
                            paddingTop: 10,
                            color: '#fff',
                            borderRadius: '8px',
                        }}
                    >
                        Select
                        <input
                            id="files"
                            accept=".csv"
                            style={{ height: 80, display: 'none' }}
                            onChange={(event) => {
                                setCsv(event.target.files[0]);
                                setFileName(event.target.files[0].name);
                                setStatus(false);
                            }}
                            type="file"
                            ref={fileInputRef} // Reference the input element
                        />
                    </Label>
                    <Label>{fileName}</Label>
                </div>
                <div style={{ marginBottom: '20px' }}>
                    <Button disabled={status} style={{ float: 'right' }} rounded={false} type="submit">
                        <Icon icon="WorkspaceImport" />
                        Import
                    </Button>
                    {loader ? (
                        <Loader />
                    ) : result.length > 0 ? (
                        <TextArea
                            disabled
                            style={{
                                marginTop: '20px',
                                width: '100%',
                                height: '300px',
                                color: '#000',
                            }}
                            value={result}
                        />
                    ) : (
                        <div />
                    )}
                </div>
            </form>
        </Box>
    );
};

export default withTheme(ImportTranslations);
