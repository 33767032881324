import { ApiClient } from 'adminjs'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { withTheme } from 'styled-components'
import Select from 'react-select'
import { FormGroup, Label, Input } from '@adminjs/design-system'
const api = new ApiClient()

const resellerJobRoleFilter = (props) => {
    const location = useLocation();
    const resourceName = location.pathname.split('/')[2];
    const { onChange, property } = props
    const [options, setOptions] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [selectedTitle, setSelectedTitle] = useState(null)

    useEffect(() => {
        const fetchOptions = async (inputValue) => {
            const response = await api.resourceAction(
                {
                    resourceId: 'JobRole',
                    actionName: 'fetchAllReseller',
                    data: {resource : resourceName, view: true, search: inputValue}
                }
            )
            const testLibraries = response.data.records.map(record => ({
                value: record._id,
                label: record.name
              }))
            setOptions(testLibraries)
        }
        fetchOptions(inputValue)
    }, [inputValue])

    const handleSelectChange = (selected) => {
        setSelectedTitle(selected)
        onChange(property.path, selected ? selected.value : '')
    }

    return (
        <FormGroup>
            Reseller
            <Select
                options={options}
                value={selectedTitle}
                onChange={handleSelectChange}
                onInputChange={(inputValue) => setInputValue(inputValue)}
                isClearable
            />
        </FormGroup>
    )
}

export default withTheme(resellerJobRoleFilter)
