import React from "react";
import { Box, H3, H5, Text } from "@adminjs/design-system";

const RedirectToUrlAction = (props) => {
  const { record } = props;

  setTimeout(() => {
    window.location = record.params.redirectUrl;
  }, 3 * 1000);

  return (
    <Box>
      <H3>{record.params.noticeMessage}</H3>
      <H5>You will be redirected in 5 seconds...</H5>
      <H5>
        <span>{`If you're not automatically redirected, please `}</span>
        <span>
          <a target="_self" href={record.params.redirectUrl}>
            click here
          </a>
        </span>
      </H5>
    </Box>
  );
};

export default RedirectToUrlAction;
