AdminJS.UserComponents = {}
import Dashboard from '../components/dashboard'
AdminJS.UserComponents.Dashboard = Dashboard
import EditComponent from '../components/actions/edit'
AdminJS.UserComponents.EditComponent = EditComponent
import ShowComponent from '../components/actions/show'
AdminJS.UserComponents.ShowComponent = ShowComponent
import CandidateResultDownload from '../components/actions/candidateResultDownload'
AdminJS.UserComponents.CandidateResultDownload = CandidateResultDownload
import TestLibraryCopy from '../components/actions/testLibraryCopy'
AdminJS.UserComponents.TestLibraryCopy = TestLibraryCopy
import BulkCsvDownload from '../components/actions/bulk-csvDownload'
AdminJS.UserComponents.BulkCsvDownload = BulkCsvDownload
import RephraseQuestionOpenAI from '../components/actions/rephraseQuestionOpenAI'
AdminJS.UserComponents.RephraseQuestionOpenAI = RephraseQuestionOpenAI
import BulkActionSoftDelete from '../components/actions/bulk-action-soft-delete'
AdminJS.UserComponents.BulkActionSoftDelete = BulkActionSoftDelete
import MarkdownEditor from '../components/markdown/markdown-editor'
AdminJS.UserComponents.MarkdownEditor = MarkdownEditor
import MultipleMarkdownEditor from '../components/markdown/multiple-markdown-editor'
AdminJS.UserComponents.MultipleMarkdownEditor = MultipleMarkdownEditor
import MarkdownViewer from '../components/markdown/markdown-viewer'
AdminJS.UserComponents.MarkdownViewer = MarkdownViewer
import CustomMultiselect from '../components/custom-multiselect'
AdminJS.UserComponents.CustomMultiselect = CustomMultiselect
import CustomMultipleView from '../components/custom-multiple-view'
AdminJS.UserComponents.CustomMultipleView = CustomMultipleView
import CustomSelectOption from '../components/custom-select-option'
AdminJS.UserComponents.CustomSelectOption = CustomSelectOption
import ImportCsvQuestions from '../components/actions/import-csv-questions'
AdminJS.UserComponents.ImportCsvQuestions = ImportCsvQuestions
import upload from '../components/upload'
AdminJS.UserComponents.upload = upload
import ApiUrlTextbox from '../components/ApiUrlTextbox'
AdminJS.UserComponents.ApiUrlTextbox = ApiUrlTextbox
import CustomColumn from '../components/custom-column'
AdminJS.UserComponents.CustomColumn = CustomColumn
import SkillId from '../components/skill-id'
AdminJS.UserComponents.SkillId = SkillId
import JobRole from '../components/job-role'
AdminJS.UserComponents.JobRole = JobRole
import JobRoleEdit from '../components/job-role-edit'
AdminJS.UserComponents.JobRoleEdit = JobRoleEdit
import JobRoleView from '../components/job-role-view'
AdminJS.UserComponents.JobRoleView = JobRoleView
import TestLibraryEdit from '../components/test-library-edit'
AdminJS.UserComponents.TestLibraryEdit = TestLibraryEdit
import ResellerJobRoleFilter from '../components/resellerJobRoleFilter'
AdminJS.UserComponents.ResellerJobRoleFilter = ResellerJobRoleFilter
import JobRoleDetailCounting from '../components/actions/jobRoleDetailCounting'
AdminJS.UserComponents.JobRoleDetailCounting = JobRoleDetailCounting
import ImportTranslationCandidate from '../components/actions/importTranslationCandidate'
AdminJS.UserComponents.ImportTranslationCandidate = ImportTranslationCandidate
import RedirectToUrl from '../components/actions/redirectToUrl'
AdminJS.UserComponents.RedirectToUrl = RedirectToUrl
import VerifyDomain from '../components/actions/verifyDomain'
AdminJS.UserComponents.VerifyDomain = VerifyDomain
import AssessmentWorkspace from '../components/actions/assessmentWorkspace'
AdminJS.UserComponents.AssessmentWorkspace = AssessmentWorkspace
import MigrateAssessment from '../components/actions/migrate-assessment'
AdminJS.UserComponents.MigrateAssessment = MigrateAssessment
import TestlifySkills from '../components/testlify-skills'
AdminJS.UserComponents.TestlifySkills = TestlifySkills
import UpdateAiGeneratedAnswer from '../components/actions/updateAiGeneratedAnswer'
AdminJS.UserComponents.UpdateAiGeneratedAnswer = UpdateAiGeneratedAnswer
import TestlibraryIdFilter from '../components/testlibraryIdFilter'
AdminJS.UserComponents.TestlibraryIdFilter = TestlibraryIdFilter
import CommentBar from '../components/comment-bar'
AdminJS.UserComponents.CommentBar = CommentBar
import TestlifyOptionsMd from '../components/markdown/testlify-options-md'
AdminJS.UserComponents.TestlifyOptionsMd = TestlifyOptionsMd
import CustomInput from '../components/custom-input'
AdminJS.UserComponents.CustomInput = CustomInput
import AdditionalBillingCost from '../components/additional-billing-cost'
AdminJS.UserComponents.AdditionalBillingCost = AdditionalBillingCost
import MergeDevComp from '../components/merge-dev-comp'
AdminJS.UserComponents.MergeDevComp = MergeDevComp
import ImportCsvAssessments from '../components/actions/import-csv-assessments'
AdminJS.UserComponents.ImportCsvAssessments = ImportCsvAssessments
import ImportQuestions from '../components/actions/import-questions'
AdminJS.UserComponents.ImportQuestions = ImportQuestions
import EditCSVQuestion from '../components/actions/edit-csv-question'
AdminJS.UserComponents.EditCSVQuestion = EditCSVQuestion
import BulkAction from '../components/actions/bulk-action'
AdminJS.UserComponents.BulkAction = BulkAction
import CustomView from '../components/custom-view'
AdminJS.UserComponents.CustomView = CustomView
import ImportJSONQuestions from '../components/actions/import-json-questions'
AdminJS.UserComponents.ImportJSONQuestions = ImportJSONQuestions
import DownloadCsvButton from '../components/actions/download-csv-button'
AdminJS.UserComponents.DownloadCsvButton = DownloadCsvButton
import RecordDifference from '../node_modules/@adminjs/logger/lib/components/RecordDifference'
AdminJS.UserComponents.RecordDifference = RecordDifference
import RecordLink from '../node_modules/@adminjs/logger/lib/components/RecordLink'
AdminJS.UserComponents.RecordLink = RecordLink