import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
    DrawerContent,
    Box,
    DrawerFooter,
    Button
} from '@adminjs/design-system'
import {BasePropertyComponent, ActionHeader, useTranslation, LayoutElementRenderer, ApiClient} from "adminjs"

const api = new ApiClient()

const Show = (props) => {
    const { resource, action } = props
    const { translateButton } = useTranslation()
    const history = useNavigate()
    const isTranslated = props.record.params.originalTestLibraryId
        ? true
        : false

    const testLibFieldsToTranslate = [
        'title',
        'overview',
        'description',
        'relevancy',
    ]

    const testLibArrayFieldsToTranslate = {
        skills: ['description', '_id'],
    }

    const questionFieldsToTranslate = ['question']

    const questionArrayFieldsToTranslate = {
        options: ['description', '_id'],
    }

    const goBack = async () => {
        if (isTranslated) {
            const translatedTestLibrary = props.record.params
            const ogTestLibrary = await api.resourceAction({
                resourceId: 'TestLibrary',
                actionName: 'customFetch',
                data: { id: translatedTestLibrary.originalTestLibraryId },
            })
            const translatedQuestions = await api.resourceAction({
                resourceId: 'Question',
                actionName: 'customFetch',
                data: { testLibraryId: translatedTestLibrary._id },
            })
            const ogQuestions = await api
                .resourceAction({
                    resourceId: 'Question',
                    actionName: 'customFetch',
                    data: { testLibraryId: ogTestLibrary.data._id },
                })
                .then(({ data }) => {
                    const translatedQuestionIds = translatedQuestions.data.map(
                        (question) => question.originalQuestionnaireId
                    )
                    const ogQuestions = data.filter((question) =>
                        translatedQuestionIds.includes(question._id)
                    )
                    return ogQuestions
                })

            const testLibTableRows = Object.entries(props.record.params)
                .map(([key, value]) => {
                    const ogValue = ogTestLibrary.data[key]
                    const translatedValue = translatedTestLibrary[key]
                    if (testLibFieldsToTranslate.includes(key)) {
                        return `<tr>
                        <td>${key}</td>
                        <td>${ogValue}</td>
                        <td>${translatedValue}</td>
                      </tr>`
                    }

                    if (key.startsWith('skills') && key.endsWith('_id')) {
                        const translatedSkillIndex = key.split('.')[1]
                        const translatedSkillDescription =
                            translatedTestLibrary[
                                `skills.${translatedSkillIndex}.description`
                            ]
                        const originalSkillId =
                            translatedTestLibrary[
                                `skills.${translatedSkillIndex}.originalSkillId`
                            ]
                        const originalSkill = ogTestLibrary.data.skills.find(
                            (skill) => skill._id === originalSkillId
                        )
                        const originalSkillDescription =
                            originalSkill.description

                        return `<tr>
                            <td>${'skills' + '.' + translatedSkillIndex}</td>
                            <td>${originalSkillDescription}</td>
                            <td>${translatedSkillDescription}</td>
                        </tr>`
                    }

                    return ''
                })
                .join('')

            const tableHtml = `
                <style>
                    table {
                    border-collapse: collapse;
                    width: 100%;
                    }
                    th, td {
                    border: 1px solid #ddd;
                    padding: 8px;
                    }
                    th {
                    background-color: #f2f2f2;
                    font-weight: bold;
                    }
                    td:first-child {
                    font-weight: bold;
                    }
                    .question-table {
                        margin-bottom: 20px;
                    }
                </style>
                <h1>Test Library</h1>
                <table>
                    <thead>
                    <tr>
                        <th></th>
                        <th>Original Test</th>
                        <th>Translated Test</th>
                    </tr>
                    </thead>
                    <tbody>
                    ${testLibTableRows}
                    </tbody>
                </table>
                <h1>Questions</h1>
                ${translatedQuestions.data
                    .map((question, index) => {
                        const ogQuestion = ogQuestions.find(
                            (q) => q._id === question.originalQuestionnaireId
                        )

                        const questionTableRows = Object.entries(question)
                            .map(([key, value]) => {
                                const ogValue = ogQuestion[key]
                                const translatedValue = question[key]

                                if (questionFieldsToTranslate.includes(key)) {
                                    return `
                                <tr>
                                    <td>${key}</td>
                                    <td class="column-width">${ogValue}</td>
                                    <td class="column-width">${translatedValue}</td>
                                </tr>`
                                }

                                if (
                                    Array.isArray(value) &&
                                    questionArrayFieldsToTranslate[key]
                                ) {
                                    if (key === 'options') {
                                        const translatedOptionRows = value
                                            .map((option, optionIndex) => {
                                                const ogOption =
                                                    ogValue[optionIndex]
                                                const translatedOption =
                                                    value[optionIndex]
                                                const optionTableRows =
                                                    Object.entries(option)
                                                        .map(
                                                            ([
                                                                optionKey,
                                                                optionValue,
                                                            ]) => {
                                                                if (
                                                                    optionKey ===
                                                                    'description'
                                                                ) {
                                                                    const ogOptionValue =
                                                                        ogOption[
                                                                            optionKey
                                                                        ]
                                                                    const translatedOptionValue =
                                                                        translatedOption[
                                                                            optionKey
                                                                        ]
                                                                    if (
                                                                        questionArrayFieldsToTranslate[
                                                                            key
                                                                        ].includes(
                                                                            optionKey
                                                                        )
                                                                    ) {
                                                                        return `
                                                        <tr>
                                                            <td>${key}.${optionIndex}.${optionKey}</td>
                                                            <td class="column-width">${ogOptionValue}</td>
                                                            <td class="column-width">${translatedOptionValue}</td>
                                                        </tr>`
                                                                    }
                                                                    return ''
                                                                }
                                                            }
                                                        )
                                                        .join('')
                                                return optionTableRows
                                            })
                                            .join('')
                                        return translatedOptionRows
                                    }
                                }

                                return ''
                            })
                            .join('')

                        return `
                            <table class="question-table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th class="column-width">Original Question</th>
                                        <th class="column-width">Translated Question</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    ${questionTableRows}
                                </tbody>
                            </table>
                        `
                    })
                    .join('')}
                `

            window.open('', '_blank').document.write(tableHtml)
        }
    }

    return (
        <Box flex flexGrow={1} flexDirection="column">
            <DrawerContent>
                {action?.showInDrawer ? <ActionHeader {...props} /> : null}
                {action.layout
                    ? action.layout.map((layoutElement, i) => (
                          <LayoutElementRenderer
                              // eslint-disable-next-line react/no-array-index-key
                              key={i}
                              layoutElement={layoutElement}
                              {...props}
                              where="show"
                          />
                      ))
                    : resource.showProperties.map((property) => (
                          <BasePropertyComponent
                              key={property.propertyPath}
                              where="show"
                              property={property}
                              resource={resource}
                              record={props.record}
                          />
                      ))}
            </DrawerContent>
            <DrawerFooter>
                {isTranslated && (
                    <Button
                        onClick={goBack}
                        variant="default"
                        size="lg"
                        type="button"
                        data-testid="button-back"
                    >
                        {translateButton('Compare Translation', resource.id)}
                    </Button>
                )}
            </DrawerFooter>
        </Box>
    )
}

export default Show