import {ApiClient} from 'adminjs'
import React from "react";
import {withTheme} from 'styled-components'
import {FormGroup, Label} from '@adminjs/design-system'
const api = new ApiClient()

const CustomMultipleValueView = (props) => {
    const {property} = props
    const [values, setValues] = React.useState([])
    const value = props.record.id
    var actionFunc, label, postdata = '';

    if(property.path == 'industryType'){
        actionFunc = 'getIndustryType';
        label = 'Industry Type';
        postdata = {view: true, id: value};
    }
    else if(property.path == 'jobRoles'){
        actionFunc = 'getJobRole';
        label = 'Job Roles';
        postdata = {view: true, id: value};
    }

    React.useEffect(()=>{
        const fetchData = async() => {
            const response = await api.resourceAction(
                {
                    resourceId: 'TestLibrary',
                    actionName: actionFunc,
                    data: postdata
                }
            )
            setValues(response.data)
        }
        fetchData()
    },[])
    return (
        <div>
            <FormGroup>
            <Label variant="light">{label}</Label>
            {values.map((val)=>{
                return(
                    <>
                        <span className="sc-bdnylx dfbaBI adminjs_Badge">{val.filtered.name}</span>
                    </>
                )
            })}
            </FormGroup>
        </div>
    )
}
export default withTheme(CustomMultipleValueView)
