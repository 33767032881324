import React from 'react'
import Select from 'react-select'
import {Label, FormGroup, selectStyles} from '@adminjs/design-system'
import {withTheme} from 'styled-components'

const TestlifySkills = (props) => {
    const {theme, onChange, record, property} = props

    const value = record.params[property.path]
    
    const getSkills = () => {
        let skills = []
        if (!record.populated.testLibraryId) return []
        let testLibraryParams = record.populated.testLibraryId.params
        let skillCount = 0
        while (testLibraryParams['skills.' + skillCount + '.description'] !== undefined) {
            skills.push({
                value: testLibraryParams['skills.' + skillCount + '._id'],
                label: testLibraryParams['skills.' + skillCount + '.description']
            })
            skillCount++
        }
        return skills
    }

    const options = getSkills()
    const selected = options.find(o => o.value === value)
    
    const returnSkill = () => {
        return <div style={{marginBottom: '30px'}}>
            <Label variant="light">Skill</Label>
            {selected?.label ?? 'Uncategorized'}
        </div>
    }
    const whereObj = {
        edit: () => {
            return <div style={{marginBottom: '50px'}}>
                <FormGroup>
                    <Label>Skill</Label>
                    <Select
                        isClearable
                        options={options}
                        value={typeof selected === 'undefined' ? '' : selected}
                        onChange={s => onChange(property.path, s?.value ?? '')}
                        isDisabled={property.isDisabled}
                    />
                </FormGroup>
            </div>
        },
        show: returnSkill,
        list: returnSkill,
        filter:options
    }

    return (
        <div>
            {whereObj[props.where] ? whereObj[props.where]() : whereObj.edit()}
        </div>
    )
}

export default withTheme(TestlifySkills)